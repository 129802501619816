import React, { useState , useEffect , useRef } from 'react';
import {  usuarioPedidoActions } from '@actions';
import { connect } from 'react-redux';
import * as moment from 'moment';

import PedidoDetalle from './pedido_detalle'
import { usuarioService } from '@services'
import ReactTable from "../common/react-table";
import {
  Switch,
  Checkbox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import {  makeStyles } from '@mui/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';

function Pedidos(props) {
  
  const {
    allPedidos, //este valor indica si voy a buscar los pedidos de un usuario en particular , o todos los pedidos de todos los usuarios

    getPedidosByIdUsuario,
    getPedidosAll,
    modificarSnYaLoPrepare
  } = props

  //la primera carga del componente se hace desde ReactTable
  
  const usuario = usuarioService.usuarioValue;
  const [rows, setRows] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const rowsPerPage = 10;


  const [openDialogConfirmacion, setOpenDialogConfirmacion] = useState(false);
  const [open, setOpen] = useState(false);
  const [idUsuarioPedidoSeleccionado,setIdUsuarioPedidoSeleccionado] = useState(0);
  const[rowValueWhenClickYaLoPrepare,setRowValueWhenClickYaLoPrepare] = useState(null);

  const isFirstRender = useRef(true)
  const [soloVerPedidosFinalizados,setSoloVerPedidosFinalizados] = useState(true) 

  //se ejecuta solamente en updates y no cuando carga la pagina
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false // toggle flag after first render/mounting
      return;
    }
    loadData(0)
  }, [soloVerPedidosFinalizados])

  const valueFormatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

  const columns = [
    {
      Header: '#',
      accessor: 'id',
      show: true,
      width: 70
    },
    {
      Header: 'Razon Social',
      accessor: 'razonSocial',
      show: allPedidos ? true : false
    },
    {
      Header: 'Fecha Creacion',
      accessor: 'fechaCreacion',
      show: true,
      Cell: props => {
        if(props.value !== null) {
          return  moment(props.value).format('DD/MM/yyyy')
        }
        else {
          return ''
        }
      }
    },
    {
      Header: 'Fecha Finalizado',
      accessor: 'fechaFinalizado',
      show: true,
      Cell: props => {
        if(props.value !== null) {
          return  moment(props.value).format('DD/MM/yyyy')
        }
        else {
          return ''
        }
      }
    },
    {
      Header: 'Fecha Ult Modif',
      accessor: 'fechaUltimaModificacion',
      show: allPedidos ? true : false,
       Cell: props => {
        if(props.value !== null) {
          return  moment(props.value).format('DD/MM/yyyy')
        }
        else {
          return ''
        }
      }
    },
    {
      Header: 'Total',
      accessor: 'total',
      show: true,
      Cell: props => valueFormatter.format(props.value),
      show: true,
      width: 120
    },  
    {
      Header: 'Finalizado?',
      accessor: 'snFinalizado',
      show: allPedidos ? true : false,
    },
    {
      Header: 'Ya lo prepare?',
      accessor: 'snYaLoPrepare',
      show: allPedidos ? true : false,
      Cell: ({ cell}) => {
        const handleChangeYaLoPrepare = (event) => {
          const payload = {
            id: cell.row.original.id,
            snYaLoPrepare: event.target.checked
          };
          setRowValueWhenClickYaLoPrepare(payload);
          
          handleOpenDialogYaLoPrepare();
      
        };
  
        //SI soloVerPedidosFinalizados ES TRUE , MUESTRO CHECKBOX , SINO MUESTRO PALABRA "SI" - "NO"
        return soloVerPedidosFinalizados    ?
                                                  cell.row.original.snFinalizado === "SI" && 
                                                  <Checkbox
                                                    checked={cell.row.original.snYaLoPrepare}
                                                    onChange={handleChangeYaLoPrepare}
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                  />
                                            :
                                                  cell.row.original.snYaLoPrepare === true ? "SI" : "NO"
      }
    },      
    {
      Header: 'Ver Detalle',
      accessor: 'verDetalle',
      show: true,
      Cell: ({ cell }) => {

        const onClickVerDetalle = () => {
          setIdUsuarioPedidoSeleccionado(cell.row.original.id)
          setOpen(true); 
        };
  
        return <Button variant="outlined" startIcon={<VisibilityIcon />} onClick={onClickVerDetalle} color='success'>
                  Ver Detalle
                </Button>
      }
    }
  ];

  function loadData (pageIndex) {
    allPedidos ? 
                  getPedidosAll(
                    {
                      skip: pageIndex,
                      take: rowsPerPage,
                      soloVerPedidosFinalizados: soloVerPedidosFinalizados
                    }
                  )
                  .then(newRows => {
                    setRows(newRows);
                    setPageCount(Math.ceil(newRows.total / rowsPerPage))
                  })
                  .catch(() => {
                    setRows(null);
                    setPageCount(0)
                  })
                
              :   getPedidosByIdUsuario(
                    {
                      idUsuario: usuario.idUsuario,
                      skip: pageIndex,
                      take: rowsPerPage
                    }
                  )
                  .then(newRows => {
                    setRows(newRows);
                    setPageCount(Math.ceil(newRows.total / rowsPerPage))
                  })
                  .catch(() => {
                    setRows(null);
                    setPageCount(0)
                  })
  }
  
  function handleChange_SoloVerPedidosFinalizados(event) { 
    setSoloVerPedidosFinalizados(event.target.checked);
  }

//------------------------ DialogYaLoPrepare  ---------------------------------------------------------------------
  const handleOpenDialogYaLoPrepare = () => {
    setOpenDialogConfirmacion(true);
  };
  
  const handleNoClick = () => {
    setOpenDialogConfirmacion(false);
  };

  const handleYesClick = () => {
    modificarSnYaLoPrepare(rowValueWhenClickYaLoPrepare)
    .then(() => {
      setOpenDialogConfirmacion(false);
      setRowValueWhenClickYaLoPrepare(null);
      loadData(pageIndex);
    })
  };

  

//------------------------ DialogConfirmacion  ---------------------------------------------------------------------
  
  const useStyles = makeStyles({
    root: {
      '& .MuiTypography-root': {
        fontSize: '1.4rem',
      },
      '& .MuiTypography-root': {
        fontSize: '1.4rem',
      },
      '& .MuiButton-root': {
        fontSize: '1.4rem', 
      },
    }
  });

  const classes = useStyles();


  return (


    <div>
      {
        open &&
        <PedidoDetalle 
            idUsuarioPedidoSeleccionado = {idUsuarioPedidoSeleccionado}
            open = {open}
            setOpen = {setOpen}
        />
      }

      {/********* Switch soloVerPedidosFinalizados *********/}  
      {
        allPedidos && 
        <>
          <Switch
            checked={soloVerPedidosFinalizados}
            onChange={handleChange_SoloVerPedidosFinalizados}
            color="primary"
            name="checkedB"
            inputProps={{ 'aria-label': 'primary checkbox' }}  
          /> <span style={{fontSize: '1.4rem'}}>Ver solamente Pedidos Finalizados</span>   
        </>
      }


      <ReactTable
        columnsTable={columns}
        data={rows ? rows.datos : []}
        dataAux={rows}
        onSelectRowReactTable = {undefined}
        viewRowFilters = {false}
        pageCount={pageCount}
        loadData = {loadData}
        pageIndex = {pageIndex}
        setPageIndex = {setPageIndex}
      />

      <Dialog open={openDialogConfirmacion} onClose={handleNoClick} className={classes.root}>
        <DialogTitle>YA LO PREPARE</DialogTitle>
        <DialogContent>
            <b>
              ¿DESEA CONFIRMAR QUE YA LO PREPARO?
            </b>
            <label>
              SI CONFIRMA EL ITEM YA NO SE VERA EN LA TABLA
            </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYesClick} color="success">SI</Button>
          <Button onClick={handleNoClick} color="error">NO</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}




const actionCreators = {
    getPedidosByIdUsuario: usuarioPedidoActions.getPedidosByIdUsuario,
    getPedidosAll: usuarioPedidoActions.getPedidosAll,
    modificarSnYaLoPrepare: usuarioPedidoActions.modificarSnYaLoPrepare
  }
  
export default connect(null, actionCreators)(Pedidos);


