import { sincronizarTablaConstantes } from './types';
import { sincronizarTablaService} from '@services';


export const sincronizarTablaActions = {
    sincronizarTabla
};


function sincronizarTabla() {
    return dispatch => {
        dispatch(request());

        document.querySelector( 'body' ).classList.remove( "loaded" );

        
        return new Promise((resolve, reject) => {
            sincronizarTablaService.sincronizarTabla()
            .then(
                bandera => { 
                    dispatch(success(bandera))
                    resolve(bandera); // respuesta correcta
                },
                error => {
                    dispatch(failure())
                    reject(error)
                })
                .finally(() => {
                    document.querySelector( 'body' ).classList.add( "loaded" );
                });
        })

        
    };
    
    function request() { return { type: sincronizarTablaConstantes.SINCRONIZAR_TABLA_REQUEST } }
    function success(bandera) { return { type: sincronizarTablaConstantes.SINCRONIZAR_TABLA_SUCCESS, bandera } }
    function failure() { return { type: sincronizarTablaConstantes.SINCRONIZAR_TABLA_FAILURE } }
}

