import React, { useEffect,useState} from 'react';
import './index.css'
import imagesLoaded from 'imagesloaded';
import DescripcionFila from './descripcion-fila'
import {
  Paper,
  Pagination
}  from '@mui/material'; 
import {  makeStyles } from '@mui/styles';

function ArticuloList2(props) {

  const {
    usuario,
    data,
    cantidad,
    setCantidad,
    onClick_IconCart,
    showArticuloInfoList2,
    setShowArticuloInfoList2,
    snVisibleDescripcionFila,
    pageCount,
    loadData,
    pageIndex,
    setPageIndex
  } = props


//descripcion-articulo
  const [selectedRowArticulo,setSelectedRowArticulo] = useState('');
  const [imagenCargada, setImagenCargada] = useState(false);
//descripcion-articulo

  useEffect(() => {
    loadData(pageIndex)
  }, [pageIndex])


  useEffect( () => {
    
    document.querySelector( '.skeleton-body.skel-shop-articulos' ).classList.remove( "loaded" );

    let imgLoad = imagesLoaded( ".articulos", { background: true } );

    imgLoad.on( 'done', function () {
        document.querySelector( '.skeleton-body.skel-shop-articulos' ).classList.add( "loaded" );
        document.querySelector('body').classList.add("loaded");
    } );
  }, [data] )

  function handleChange_CantidadArticulos(newValue) { //la funcion "handleChange_CantidadArticulos" es llamada por el Children <DescripcionFila/>
    const value = newValue;
    const setValue = value;
    setCantidad(setValue);
  }


//======================================================== ON SELECT ROW ================================================
  function onSelectRowReactTable(data) {
    setShowArticuloInfoList2(!!data);
    setSelectedRowArticulo(data);
    setCantidad(1);
    setImagenCargada(false);
  }

//====================================================  PAGINATION  =========================================================    
 
  const useStyles = makeStyles({
    pagination: {
      "& .MuiPaginationItem-root": {
        fontSize: '1.4rem',
        '&.Mui-selected': {
          background: 'blue',
          color: 'white',
        },
      }
    }
  });

  const classes = useStyles();

  const onDataPageChange = (event, page) => {
    setPageIndex(page - 1)

    document.querySelector( '.skeleton-body.skel-shop-articulos' ) && document.querySelector( '.skeleton-body.skel-shop-articulos' ).classList.remove( "loaded" );

    let imgLoad = imagesLoaded( ".articulos", { background: true } );

    imgLoad.on( 'done', function () {
        document.querySelector( '.skeleton-body.skel-shop-articulos' ) && document.querySelector( '.skeleton-body.skel-shop-articulos' ).classList.add( "loaded" );
    } );

    window.scrollTo( {
        top: 0
    } );
  }

//====================================================  RENDER  =========================================================    
  return (
    <>
      {
         usuario &&
         <p className='articuloList2-mensaje'>*Hacer click en una fila para comprar</p>
      }

      <br/>
      
      <div className='row'>
        <div className="col-lg-9 skeleton-body skel-shop-articulos loaded">
          <div className="articulos mb-3">
            {
                data.map( ( item, index ) =>
                    <div key={ "seleton-" + index } className={usuario && "articuloList2-cursorPointer"}>
                      <div className="skel-pro skel-pro-list">
                      </div>

                      <Paper 
                        className = "articuloList2-Paper" 
                        onClick={()=> onSelectRowReactTable && onSelectRowReactTable(item)}
                        elevation={3}
                      >
                        <div className="row pr-2">
                            <div className="col-lg-3">
                              <div className="articuloList2-articuloPhoto">
                                  <img
                                      src={item.pathImagenArticulo ? item.pathImagenArticulo + '?' + Date.now() : '/assets/images/imagenes_articulos/sin_imagen.png' + '?' + Date.now() }    
                                      loading="lazy"
                                      style= {{
                                        height: '100%', //si no esta cargada la imagen , pongo el height en 0
                                        width: '100%',  //si no esta cargada la imagen , pongo el width en 0
                                        objectFit: 'contain'
                                      }} 
                                  />
                              </div>
                            </div>

                            <div className="col-lg-9 order-last">
                                <div>
                                  

                                    <h3 className="articulo-title articuloList2-articuloTitle">
                                        { item.codigoArticulo }
                                    </h3>

                                    <div className="articulo-content articuloList2-articuloContent">
                                        <p>{ item.descripcionArticulo }</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </Paper>
                    </div>
                ) 
            }
          </div>
          <Pagination
            count={pageCount}
            onChange={onDataPageChange}
            page={pageIndex + 1}
            className={classes.pagination}
          />
        </div>

        {
        
          snVisibleDescripcionFila && showArticuloInfoList2 && 
            <div className="col-lg-3 skeleton-body skel-shop-articulos loaded">
              <DescripcionFila 
                  selectedRowArticulo={selectedRowArticulo} //le paso al Children <DescripcionFila/> la funcion selectedRowArticulo
                  cantidad = {cantidad} //le paso al Children <DescripcionFila/> la constante cantidad
                  onClick={onClick_IconCart} //le paso al Children <DescripcionFila/> la funcion onClick_IconCart
                  onChange={handleChange_CantidadArticulos}  //le paso al Children <DescripcionFila/> la funcion handleChange_CantidadArticulos
                  usuario={usuario}
                  imagenCargada = {imagenCargada}
                  setImagenCargada = {setImagenCargada}
              />
            </div>
        }        
      </div>
    </>
  );

}

  
export default ArticuloList2