import React,{useEffect} from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Helmet } from 'react-helmet';


import { usuarioService} from '@services';

// import Custom Components
import Breadcrumb from '@breadcrumb';
import DownloadCatalogosPage from '../../download_catalogos';
import Register_UpdateUserPage from '../register-updateUser';
import UsuarioCuentaCorrientePage from './usuario_cuenta_corriente';
import Pedidos from '../../../pedidos';

function DashboardPage() {

    const usuario = usuarioService.usuarioValue;

    useEffect( () => {
        document.querySelector( 'body' ).classList.add( "loaded" ); //esto lo agrego ya que no llamo en este componente a reactTable , y si no pongo esto queda corriendo los puntitos rojos indefinidamente      
    }, [] )

    return (
        <div>
            <Helmet>
                <title>Encendido Alsina | Mi Cuenta</title>
            </Helmet>

            <h1 className="d-none">Encendido Alsina - Mi Cuenta</h1>

            <div className="main">
                <Breadcrumb parent1={ [ "Mi Cuenta", "usuario/dashboard" ] } />

                <div className="page-content">
                    <div className="dashboard">
                        <div className="container">
                            <ul className="nav nav-dashboard flex-column mb-3 mb-md-0" role="tablist">
                                <Tabs selectedTabClassName="active show">
                                    <div className="row">
                                        <aside className="col-md-4 col-lg-3">
                                            <TabList>
                                                <Tab className="nav-item">
                                                    <span className="nav-link">Mi Cuenta</span>
                                                </Tab>

                                                <Tab className="nav-item">
                                                    <span className="nav-link">Pedidos</span>
                                                </Tab>

                                                <Tab className="nav-item">
                                                    <span className="nav-link">Catálogo descargas</span>
                                                </Tab>

                                               

                                                <Tab className="nav-item">                                 
                                                    <span className="nav-link">Detalle usuario</span>
                                                </Tab>

                                                <Tab className="nav-item">                                 
                                                    <span className="nav-link">Detalle cuenta</span>
                                                </Tab>

                                               
                                                
                                            </TabList>
                                        </aside>

                                        <div className="col-md-8 col-lg-9" style={ { marginTop: "1rem" } }>
                                            <div className="tab-pane">
                                                <TabPanel>
                                                    <p>Hola <span className="font-weight-normal text-dark">{usuario.razonSocial}</span> <span> </span> 
                                                    <br />
                                                        Desde el panel de su cuenta puede ver sus pedidos, administrar su dirección de envio y facturación, y editar su contraseña y los detalles de su cuenta</p>
                                                </TabPanel>

                                                <TabPanel>  
                                                    <Pedidos
                                                        allPedidos = {false}
                                                    />
                                                </TabPanel>

                                                <TabPanel>
                                                    <DownloadCatalogosPage vengoDesdeMiCuenta = {true} />
                                                
                                                </TabPanel>

                                                <TabPanel>
                                                    <Register_UpdateUserPage deDondeVengo={2} />
                                                </TabPanel>

                                                <TabPanel>
                                                    <UsuarioCuentaCorrientePage/>
                                                </TabPanel>

                                             
                                            </div>
                                        </div>
                                    </div>
                                </Tabs>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo( DashboardPage );