import React from 'react';

import AdminUsersInactivesListPage from "./list";

import { Helmet } from 'react-helmet';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';

export default function AdminUsersInactivesPage() {
  return (
    <div>
      <Helmet>
          <title>Encendido Alsina - Admin Usuarios Inactivos</title>
      </Helmet>
      
      <h1 className="d-none">Encendido Alsina - Admin Usuarios Inactivos</h1>

      <div className="main">
        <Breadcrumb  parent1={ [ "Admin Usuarios Inactivos", "admin_users_inactives" ] } adClass="mb-2" />

        <div className="page-content">
            <div className="container">
              <AdminUsersInactivesListPage/>
            </div>
        </div>
      </div>
    </div>
  );
}