import React, {useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { safeContent } from '@utils';
import { usuarioPedidoActions , dropDownCartMenuActions } from '@actions';
import LoadMultipleImg from '../load-multiple-img'





function CartMenu( props ) {
    const { 
            usuario,
 
            callGetPedidoNotFinalizedByIdUsuario,
            isOpenDropdownCartMenu,

            eliminarArticuloPedido,
            getPedidoNotFinalizedByIdUsuario,
            openDropDownCartMenu,
            closeDropDownCartMenu
          } = props;
    
    const [usuarioPedidoNotFinalized, setUsuarioPedidoNotFinalized] =  useState(null);
   
    useEffect(() => { 
        usuario &&  initial()  
      }, [usuario,callGetPedidoNotFinalizedByIdUsuario])

    useEffect(() => { 
        usuario && isOpenDropdownCartMenu && initial() 
        
      }, [usuario,callGetPedidoNotFinalizedByIdUsuario , isOpenDropdownCartMenu])
  
   

    function initial() {
        getPedidoNotFinalizedByIdUsuario(usuario.idUsuario)
        .then((response) => {
            setUsuarioPedidoNotFinalized(response.usuarioPedido);
        })
    }


    return (
      
        <div className="dropdown cart-dropdown"   
             onMouseEnter={() => openDropDownCartMenu()}
             onMouseLeave={() => closeDropDownCartMenu()}
        >
            <Link to={ usuario ? `${process.env.PUBLIC_URL}/carrito_compra` : `${process.env.PUBLIC_URL}/usuario/login`} className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                <i className="icon-shopping-cart"></i>
                <span className="cart-count">{ (usuario && usuarioPedidoNotFinalized) ? usuarioPedidoNotFinalized.usuarioPedidoDetalle.length : 0 }</span>
                <span className="cart-txt">Carrito</span>
            </Link>
            
            {
                usuario ?
                            <div className={ `dropdown-menu dropdown-menu-right ${ !usuarioPedidoNotFinalized ? 'text-center' : ''}` } >
                                {
                                    (usuario && usuarioPedidoNotFinalized && usuarioPedidoNotFinalized.usuarioPedidoDetalle.length > 0)
                                        ? 
                                            <div>
                                                <div className="dropdown-cart-articulos">
                                                    { usuarioPedidoNotFinalized.usuarioPedidoDetalle.map( ( item, index ) => {
                                                    
                                                        return (<div className="articulo" key={ index }>
                                                            
                                                            <div className="articulo-cart-details">
                                                                <h4 className="articulo-title">
                                                                    <Link to="#" dangerouslySetInnerHTML={ safeContent( item.codigoArticulo ) }></Link>
                                                                    <span style = {{marginRight: '5px' , marginLeft: '5px', color: 'blue'}}>x</span>
                                                                    <span className="cart-articulo-qty">{ item.cantidad }</span>
                                                                </h4>

                                                                {/* <span className="cart-articulo-info">
                                                                    <span className="cart-articulo-qty">{ item.cantidad }</span>
                                                                    x ${ item.precioListaPorCoeficientePorMedioIva.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }
                                                                </span> */}
                                                            </div>


                                                
                                                            <LoadMultipleImg 
                                                                item = {item}
                                                                widthProp = '50px'
                                                                heightProp = '50px' 
                                                            />
                                                        

                                                            <button className="btn-remove"  title="Remove Articulo" onClick={ () => eliminarArticuloPedido( usuario.idUsuario,item.articulo.id ) }><i className="icon-close"></i></button>
                                                            
                                                        </div>)
                                                    } ) }
                                                </div>
                                                {/* <div className="dropdown-cart-total">
                                                    <span>Total</span>

                                                    <span className="cart-total-price">${ usuarioPedidoNotFinalized.total.toLocaleString( undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 } ) }</span>
                                                </div> */}

                                                <br/>
                                                <div className="dropdown-cart-action">
                                                    <Link to={ `${process.env.PUBLIC_URL}/carrito_compra` } className="btn btn-primary">Ver carrito</Link>
                                                    {/* <Link to={ `${process.env.PUBLIC_URL}/shop/checkout` } className="btn btn-outline-primary-2"><span>Pagar</span><i className="icon-long-arrow-right"></i></Link> */}
                                                </div>
                                            </div>
                                        :    <p>No hay articulos en el carrito</p> 
                                }
                            </div>
                    :
                        null
            }
        </div>
    );
}


const mapStateToProps = (state) => {
    return { //cualquier cosa que retorno aca , va a estar disponible como propiedad (props) en nuestro componente
        callGetPedidoNotFinalizedByIdUsuario : state.usuarioPedidoReducer.callGetPedidoNotFinalizedByIdUsuario,
        isOpenDropdownCartMenu : state.dropDownCartMenuReducer.isOpenDropdownCartMenu,
    }
  }



const actionCreators = {
    eliminarArticuloPedido: usuarioPedidoActions.eliminarArticuloPedido,
    getPedidoNotFinalizedByIdUsuario: usuarioPedidoActions.getPedidoByIdUsuarioNotFinalized,
    openDropDownCartMenu: dropDownCartMenuActions.openDropDown,
    closeDropDownCartMenu: dropDownCartMenuActions.closeDropDown
};



export default connect(mapStateToProps, actionCreators)(CartMenu);
