import React, {  useEffect } from 'react';
import { ToastContainer } from 'react-toastify';

// import Custom Components
import Header from './common/header/header';
import Footer from './common/footer';
import MobileMenu from './common/mobile-menu';
import { usuarioService } from '@services'
import { connect } from 'react-redux';

function App( props ) {
    const {
        children
    } = props

    const container = "container";
    const prevPath = '';
    const usuario = usuarioService.usuarioValue;
    useEffect( () => {
        document.querySelector( "#root" ).classList.add( "loaded" );        
    }, [] )

    return (
        <div>
            <div className="page-wrapper">            
                <Header 
                    container={ container } 
                    urls={ prevPath } 
                    usuario = {usuario} 
                />



                {
                    children
                }

                 <Footer container={ container } /> 

                <ToastContainer autoClose={ 3000 } className="toast-container" />
            </div>

            <MobileMenu usuario = {usuario} />

        </div>
    );
}


export default connect(null, null)(App);

