export const alertConstantes = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const usuarioConstantes = {
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_FAILURE: 'REGISTER_FAILURE',

    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',

    VERIFY_USER_REQUEST: 'VERIFY_USER_REQUEST',
    VERIFY_USER_SUCCESS: 'VERIFY_USER_SUCCESS',
    VERIFY_USER_FAILURE: 'VERIFY_USER_FAILURE',

    DELETE_INACTIVE_USER_REQUEST: 'DELETE_INACTIVE_USER_REQUEST',
    DELETE_INACTIVE_USER_SUCCESS: 'DELETE_INACTIVE_USER_SUCCESS',
    DELETE_INACTIVE_USER_FAILURE: 'DELETE_INACTIVE_USER_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    VALIDATE_RESET_TOKEN_REQUEST: 'VALIDATE_RESET_TOKEN_REQUEST',
    VALIDATE_RESET_TOKEN_SUCCESS: 'VALIDATE_RESET_TOKEN_SUCCESS',
    VALIDATE_RESET_TOKEN_FAILURE: 'VALIDATE_RESET_TOKEN_FAILURE',

    GET_BY_ID_REQUEST: 'GET_BY_ID_REQUEST',
    GET_BY_ID_SUCCESS: 'GET_BY_ID_SUCCESS',
    GET_BY_ID_FAILURE: 'GET_BY_ID_FAILURE',

    UPDATE_REQUEST: 'UPDATE_SUCCESS',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    UPDATE_FAILURE: 'UPDATE_FAILURE',

    GET_USERS_INACTIVES: 'GET_USERS_INACTIVES'
   
};


export const usuarioPedidoConstantes = {
    AGREGAR_ARTICULO_PEDIDO_SUCCESS: 'AGREGAR_ARTICULO_PEDIDO_SUCCESS',
    ELIMINAR_ARTICULO_PEDIDO_SUCCESS: 'ELIMINAR_ARTICULO_PEDIDO_SUCCESS',
    MODIFICAR_ARTICULO_PEDIDO_SUCCESS: 'MODIFICAR_ARTICULO_PEDIDO_SUCCESS',
    GET_CANTIDAD_ARTICULOS_PEDIDO: 'GET_CANTIDAD_ARTICULOS_PEDIDO',
    GET_PEDIDO_BY_ID_USUARIO_NOT_FINALIZED_SUCCESS : 'GET_PEDIDO_BY_ID_USUARIO_NOT_FINALIZED_SUCCESS',
    FINALIZAR_PEDIDO_SUCCESS: 'FINALIZAR_PEDIDO_SUCCESS',
    GET_PEDIDOS_ALL_SUCCESS: 'GET_PEDIDOS_ALL_SUCCESS',
    GET_PEDIDOS_BY_ID_USUARIO_SUCCESS: 'GET_PEDIDOS_BY_ID_USUARIO_SUCCESS',
    MODIFICAR_SNYALOPREPARE: 'MODIFICAR_SNYALOPREPARE',
    GET_PEDIDO_DETALLES_BY_ID_USUARIO_PEDIDO_SUCCESS: 'GET_PEDIDO_DETALLES_BY_ID_USUARIO_PEDIDO_SUCCESS'
};

export const marcaConstantes = {
    GET_ALL_WITH_PATH_IMG_AND_ACTIVE_REQUEST : 'GET_ALL_WITH_PATH_IMG_AND_ACTIVE_REQUEST',
    GET_ALL_WITH_PATH_IMG_AND_ACTIVE_SUCCESS: 'GET_ALL_WITH_PATH_IMG_AND_ACTIVE_SUCCESS',
    
    SELECTED_MARCA_SUCCESS: 'SELECTED_MARCA_SUCCESS',
    REMOVE_SELECTED_MARCA_SUCCESS: 'REMOVE_SELECTED_MARCA_SUCCESS'
};

export const familiaConstantes = {
    GET_ALL_ACTIVE_REQUEST : "GET_ALL_ACTIVE_REQUEST",
    GET_ALL_ACTIVE_SUCCESS_FAMILIA: "GET_ALL_ACTIVE_SUCCESS_FAMILIA",

    GET_BY_MARCA_REQUEST : 'GET_BY_MARCA_REQUEST',
    GET_BY_MARCA_SUCCESS: 'GET_BY_MARCA_SUCCESS',
};

export const empresaConstantes = {
    GET_BY_ID_REQUEST: 'GET_BY_ID_REQUEST',
    GET_BY_ID_SUCCESS: 'GET_BY_ID_SUCCESS',
    GET_BY_ID_FAILURE: 'GET_BY_ID_FAILURE',
    
};

export const articuloConstantes = {
    GET_BY_FILTERS_REQUEST : 'GET_BY_FILTERS_REQUEST',
    GET_BY_FILTERS_SUCCESS: 'GET_BY_FILTERS_SUCCESS',
    GET_BY_FILTERS_FAILURE: 'GET_BY_FILTERS_FAILURE',
    MODIFICAR_ARTICULO_IMAGEN_SUCCESS: 'MODIFICAR_ARTICULO_IMAGEN_SUCCESS',
};

export const downloadCatalogoConstantes = {
    AGREGAR_DOWNLOAD_CATALOGO_SUCCESS : 'AGREGAR_DOWNLOAD_CATALOGO_SUCCESS',
    ELIMINAR_DOWNLOAD_CATALOGO_SUCCESS: 'ELIMINAR_DOWNLOAD_CATALOGO_SUCCESS',
    MODIFICAR_DOWNLOAD_CATALOGO_SUCCESS: 'MODIFICAR_DOWNLOAD_CATALOGO_SUCCESS',
    GET_ALL_ACTIVE_SUCCESS_DOWNLOADCATALOGO: 'GET_ALL_ACTIVE_SUCCESS_DOWNLOADCATALOGO'
};


export const usuarioCuentaCorrienteConstantes = {
    GET_ALL_ACTIVE_SUCCESS_USUARIOCUENTACORRIENTE : 'GET_ALL_ACTIVE_SUCCESS_USUARIOCUENTACORRIENTE',
};


export const sincronizarTablaConstantes = {
    SINCRONIZAR_TABLA_REQUEST: 'SINCRONIZAR_TABLA_REQUEST',
    SINCRONIZAR_TABLA_SUCCESS: 'SINCRONIZAR_TABLA_SUCCESS',
    SINCRONIZAR_TABLA_FAILURE: 'SINCRONIZAR_TABLA_FAILURE'
}

export const dropDownCartMenuConstantes = {
    OPEN_DROPDOWN: 'OPEN_DROPDOWN',
    CLOSE_DROPDOWN: 'CLOSE_DROPDOWN'
};