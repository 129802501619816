import { articuloConstantes } from './types';
import { articuloService , alertService} from '@services';


export const articuloActions = {
    getByFilters,
    modificarImagenArticulo
};


function getByFilters(pageIndex,rowsPerPage,idArticulo,marcaSeleccionadaComboBox,familiaSeleccionadaComboBox,codigoArticulo,descripcionArticulo,utilidad,snOferta,searchFilterGlobal) {
    const payload = {
        skip: pageIndex,
        take: rowsPerPage,
        idArticulo : idArticulo ? idArticulo : "", 
        comboBoxMarca : marcaSeleccionadaComboBox ? marcaSeleccionadaComboBox : null, //comboBoxMarca
        comboBoxFamilia : familiaSeleccionadaComboBox ? familiaSeleccionadaComboBox : null, //comboBoxFamilia
        codigoArticulo : codigoArticulo ? codigoArticulo : "",
        descripcionArticulo : descripcionArticulo ? descripcionArticulo : '',
        utilidad,
        oferta: snOferta,
        searchFilterGlobal: searchFilterGlobal
    };




    return dispatch => {
        dispatch(request());

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            articuloService.getByFilters(payload)
            .then(
                articulos => { 
                    dispatch(success(articulos))
                    resolve(articulos); // respuesta correcta
                },
                error => {
                    dispatch(failure(error.toString()))
                    alertService.error(error);
                    reject(error)
            })
            //document.querySelector( 'body' ).classList.add( "loaded" ); -> se agrega en useEffect en ReactTable o en articuloList2
        })

        
    };

    function request() { return { type: articuloConstantes.GET_BY_FILTERS_REQUEST } }
    function success(articulos) { return { type: articuloConstantes.GET_BY_FILTERS_SUCCESS, articulos } }
    function failure(error) { return { type: articuloConstantes.GET_BY_FILTERS_FAILURE, error } }
}

function modificarImagenArticulo(formData) {
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            articuloService.modificarImagenArticulo(formData)
                    .then(
                        articuloModificado => { 
                            dispatch( success(articuloModificado));
                            alertService.success('Imagen articulo editada correctamente');
                            resolve(articuloModificado); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                    })
                    .finally(() => {
                        document.querySelector( 'body' ).classList.add( "loaded" );
                    });
            })
    };

  
    function success(articuloModificado) { return { type: articuloConstantes.MODIFICAR_ARTICULO_IMAGEN_SUCCESS,  articuloModificado } }

}