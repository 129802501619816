import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import Breadcrumb from '@breadcrumb';
import '../index.css'
import { usuarioActions } from '@actions';
import { connect } from 'react-redux';
import ButtonCustom from '@buttonCustom';

function ForgotPasswordPage(props) {
    const { forgotPassword } = props;
    const initialValues = {
        email: ''
    };

    useEffect( () => {  
        document.querySelector( 'body' ).classList.add( "loaded" );  //esto lo agrego ya que no llamo en este componente a reactTable , y si no pongo esto queda corriendo los puntitos rojos indefinidamente    
    } )

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email no es valido')
            .required('Email es requerido')
            .max(50, 'Maximo 50 caracteres'),
    });

    function onSubmit({ email }, { setSubmitting }) {
        forgotPassword(email)
        .catch(() => 
            setSubmitting(false)
        )
    }


    return (
        <div>
            <Helmet>
                <title>Encendido Alsina - Olvide mi contraseña</title>
            </Helmet>
            
            <h1 className="d-none">Encendido Alsina - Olvide mi contraseña</h1>
            
            <div className="main">
                <Breadcrumb title="Olvide mi contraseña" parent1={ [ "Iniciar Sesión", "usuario/login" ] } />

                <div className="page-content">
                        <div className="container">
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                                {({ errors, touched, isSubmitting }) => (
                                    <Form>
                                        <h3 className="card-header">Olvide mi contraseña</h3>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <Field name="email" type="text" className={'form-control form-controlCustom' + (errors.email && touched.email ? ' is-invalid' : '')} />
                                                <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <ButtonCustom 
                                                        type="submit" 
                                                        isSubmitting={isSubmitting} 
                                                        text="Enviar"
                                                    />
                                                    <Link to="login" className="btn btn-link">Cancelar</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
        </div>        
    )
}




const actionCreators = {
    forgotPassword : usuarioActions.forgotPassword
};



export default connect(null, actionCreators)(ForgotPasswordPage);


