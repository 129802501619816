import { fetchWrapper } from '@helpers';
const baseUrl = `/downloadCatalogo`;


export const downloadCatalogoService = {
    agregarDownloadCatalogo,
    eliminarDownloadCatalogo,
    modificarDownloadCatalogo,
    getAllActive,
};

function agregarDownloadCatalogo(formData) {
    return fetchWrapper.postUpload(`${baseUrl}/agregar-catalogo-download`, formData);
}

function eliminarDownloadCatalogo(payload) {
    return fetchWrapper.post(`${baseUrl}/eliminar-catalogo-download`, payload);
}

function modificarDownloadCatalogo(formData) {
    return fetchWrapper.postUpload(`${baseUrl}/modificar-catalogo-download`, formData);
}

function getAllActive (payload) {
    const skip = parseInt(payload.skip);
    const take = parseInt(payload.take);
    return fetchWrapper.post(`${baseUrl}/get-all-active`, {
        skip,
        take,
    });
}