import React, {
  useMemo,
  useRef,
  useState,
  useEffect
} from 'react'
import {
  useTable,
  useFilters,
  usePagination,
  useResizeColumns,
  useBlockLayout,
  useRowSelect
} from 'react-table'
import {  makeStyles } from '@mui/styles';
import './index.scss'

import {
  Paper,
  Pagination
} from '@mui/material';


function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <input
      className='form-control input-search'
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined)
      }}
      maxLength={20}
    />
  )
}


function ReactTable({
  columnsTable,
  data,
  dataAux,
  onSelectRowReactTable,
  viewRowFilters,
  pageCount: controlledPageCount,
  loadData,
  pageIndex,
  setPageIndex
}) {

  const refTable = useRef();
  const [dimensionsReactTable, setDimensionsReactTable] = useState({ width: 0, height: 0 });
  const [widthBrowser, setWidthBrowser] = useState(window.innerWidth);

  useEffect(() => {

    //SIZE REACT TABLE
    setDimensionsReactTable({
      width: refTable.current.offsetWidth,
      height: refTable.current.offsetHeight
    })

    if(dataAux) {  
      document.querySelector( 'body' ).classList.add( "loaded" ); 
    }

    //SIZE BROWSER
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };



  }, [data]);

  useEffect(() => {
    loadData(pageIndex)
  }, [pageIndex])

//====================================================  COLUMNS  =========================================================    
  const handleWindowSizeChange = () => {
    //SIZE BROWSER
    setWidthBrowser(window.innerWidth);

    //SIZE REACT TABLE
    setDimensionsReactTable({
      width: refTable.current.offsetWidth,
      height: refTable.current.offsetHeight
    })
  };

  //sumo los width de las columnas que tienen el campo "width" y estan visibles
  const totalWidthDeclaradoEnColumnasVisibles = columnsTable.filter(c => c.width !== undefined && c.show === true).reduce((totalWidthDeclared, columnTable) => totalWidthDeclared + columnTable.width, 0);

  //cantidad de columnas que no tienen el campo "width" y estan visibles
  const cantidadColumnasSinDeclaraWidthVisibles = columnsTable.filter(c => c.show === true && c.width === undefined).length;

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,
      minWidth: 30,

      //1- le saco al width total de la tabla , el width total de las columnas que tienen width declarado
      //2- a ese valor le divido la cantidad de columnas que no tienen width declarado
      //3- con esto obtengo el width de las columnas que no tienen width declarado para completar el width total de la tabla
      width: dimensionsReactTable.width > 0 ? (dimensionsReactTable.width - totalWidthDeclaradoEnColumnasVisibles) / cantidadColumnasSinDeclaraWidthVisibles : 150,
      maxWidth: 1200,
    }),
    [dimensionsReactTable, widthBrowser],
  );


  const columns = useMemo(() => {
    return [...columnsTable];
  }, [defaultColumn]);

  
//====================================================  useTable  =========================================================    

  const propsUseTable = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columns.map(column => {
          if (column.show === false) return column.accessor;
        }),
        pageIndex: 0
      },
      manualPagination: true,
      defaultColumn,
      pageCount: controlledPageCount,
      autoResetPage: false,
      pageIndex
    },
    useBlockLayout,
    useResizeColumns,
    useFilters,
    usePagination,
    useRowSelect

  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = propsUseTable;

  
//====================================================  PAGINATION  =========================================================    
 
  const useStyles = makeStyles({
    pagination: {
      "& .MuiPaginationItem-root": {
        fontSize: '1.4rem',
          '&.Mui-selected': {
            background: 'blue',
            color: 'white',
          },
        }
      }
    });

  const classes = useStyles();

  const onDataPageChange = (event, page) => {
    setPageIndex(page - 1)
  }

//====================================================  RENDER  =========================================================    
 
  return (
    <>

      <Paper className='div'>
        <div {...getTableProps()} className="table mb-2" ref={refTable}>
          <div className='thead'>
            {headerGroups.map((headerGroup) => {
              return (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map(column => {
                    return (
                      <div {...column.getHeaderProps()} className="th">
                        {column.render('Header')}

                        <div
                          {...column.getResizerProps()}
                          className="resizer isResizing"
                        />
                      </div>


                    )
                  })}
                </div>
              )
            })}

            {
              viewRowFilters &&
              headerGroups.map((headerGroup) => {
                return (
                  <div {...headerGroup.getHeaderGroupProps()} className="input-search">
                    {headerGroup.headers.map(column => {
                      return (
                        <div {...column.getHeaderProps()} className="input-search">
                          {column.render('Filter')}
                        </div>


                      )
                    })}
                  </div>
                )
              })
            }
          </div>

          <div {...getTableBodyProps()} >
            {
              page.length 
              ?
                page.map((row) => {
                  prepareRow(row)
                  return (
                    <div
                      {...row.getRowProps()}
                      onClick={() => onSelectRowReactTable && onSelectRowReactTable(row.original)}
                      className={"tr" + ' ' + (onSelectRowReactTable && "tr-cursor-pointer")}
                    >
                      {row.cells.map(cell => {
                        return (
                          <div {...cell.getCellProps()} className="td">
                            {cell.render('Cell')}
                          </div>
                        )
                      })}
                    </div>
                  )
                })
              :
                <div>
                  <div className='td-sin-datos'>Sin datos</div>
                </div>

            }

          </div>
        </div>
        <Pagination
          count={controlledPageCount}
          onChange={onDataPageChange}
          page={pageIndex + 1}
          className={classes.pagination}
        />

        <br/>
      </Paper>

    </>
  )
}


export default ReactTable

