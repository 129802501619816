
import React, { useState, useEffect } from 'react';
import { marcaActions , familiaActions } from '@actions';
import { connect } from 'react-redux';

import {
  TextField,
  Box,
  Switch,
  Autocomplete,
  List,
  ListItem,
  Drawer
} from '@mui/material';
  

import {  makeStyles } from '@mui/styles';
import {alertService,usuarioService} from '@services';


//estilos personalizados del componente
const useStyles = makeStyles({
  textField: {
        "& .MuiInputBase-root":{
          fontSize: '1.4rem'
        }
     },
  formLabel: {
    "& .MuiFormLabel-root , & .MuiInputBase-input":{
      fontSize: '1.4rem'
    }
  },
  button: {
    display: 'inline-block',
    marginRight: '10px',
    fontSize: '1.4rem'
  } 
});




function MenuFilter(props) {
  const [marcas,setMarcas] = useState([]) 
  const [familias,setFamilias] = useState([]) 

  const usuario = usuarioService.usuarioValue;

  const [stateContentMenuFilter, setStateContentMenuFilter] = useState({
    busquedaFilter: false,
  });

  const {
    loadData,
    cleanReactTable,

    pageIndex,
    setPageIndex,

    idArticulo,
    setIdArticulo,
    marcaSeleccionadaComboBox,
    setMarcaSeleccionadaComboBox,
    familiaSeleccionadaComboBox,
    setFamiliaSeleccionadaComboBox,
    codigoArticulo,
    setCodigoArticulo,
    descripcionArticulo,
    setDescripcionArticulo,
    snOferta,
    setSnOferta,
    utilidad,
    setUtilidad,
        
    snVisibleIdArticulo,
    snVisibleUtilidad,
    snVisibleSwitchOferta,
  
    //mapStateToProps
    marcaSelectedHome,

    //actions
    action_removeSelectedMarca,
    action_loadComboBoxMarca,
    action_loadComboBoxFamilia,
    action_loadComboBoxFamiliaByMarca,    
  } = props


//======================================================== TOGGLE DRAWER ==============================================
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setStateContentMenuFilter({...stateContentMenuFilter,[anchor]: open });
  };


//======================================================== USE EFECTS ================================================
  
  async function loadComponentAsync() {
      await loadComboBoxMarca();

      if(marcaSelectedHome) { //si hay una marca cargada desde Home , la seteo en el combo MARCA
        const marcaSeleccionadaComboBox = {
          descripcionMarca: marcaSelectedHome.descripcionMarca,
          list_IdTablaMarca: marcaSelectedHome.list_IdTablaMarca
        }

        //seteo el valor de la marca
        await setMarcaSeleccionadaComboBox(marcaSeleccionadaComboBox); 
  
        //llamo a la funcion para cargar el combo familia en base a MARCA
        await loadComboBoxFamiliaByMarca(marcaSeleccionadaComboBox);
      }
      else {
        await loadComboBoxFamilia();
      }
  }

  useEffect( () => {
    loadComponentAsync()     
  }, []);



//======================================================== ID ARTICULO ================================================
function handleChangeIdArticuloOnlyNumbers (e) {
  const value = e.target.value.replace(/\D/g, "");
  setIdArticulo(value)
}

//====================================================  COMBOBOX MARCA  =========================================================    
  async function onChangeComboBoxMarca(event,newValue){
    
    
    //si newValue no tiene valor , y hay una marca que viene desde Home , remuevo la marca que viene desde Home
    if(newValue === "" && marcaSelectedHome) {
        await action_removeSelectedMarca();
    }
    await setMarcaSeleccionadaComboBox(newValue); //newValue contiene [DescripcionMarca -  List<int> List_IdTablaMarca]
    await setFamiliaSeleccionadaComboBox(null);  //cada vez que se modifica el combo marca , se modifica tmb el combo familia

    if(newValue === null) {
      await loadComboBoxFamilia();
    } else {
      await loadComboBoxFamiliaByMarca(newValue); //llamo a la funcion para cargar el combo familia con filtro
    }
  }



  //cargo el combo marca
  async function loadComboBoxMarca() {
    await action_loadComboBoxMarca()
    .then(m => {  
        setMarcas(m);       
    });
  }

//====================================================  COMBOBOX FAMILIA  =========================================================      
  async function onChangeComboBoxFamilia(event,newValue){
    await setFamiliaSeleccionadaComboBox(newValue); //newValue contiene [DescripcionFamilia - IdTablaFamilia]
  }

  async function loadComboBoxFamiliaByMarca(marca) {
    if(marca) {  //si marca tiene algo escrito ... voy a buscar las familias de esa marca
      await action_loadComboBoxFamiliaByMarca(marca) //esta funcion viene de actionCreators
      .then(f => {
        setFamilias(f)
        if(f.length === 1) {
          var newObj = Object.assign({}, ...f ) //convierto array en objeto
          setFamiliaSeleccionadaComboBox(newObj); //newObj contiene [DescripcionMarca -  List<int> List_IdTablaMarca]
        }
      })
    }
  } 

  //cargo el combo familia
  async function loadComboBoxFamilia() {
    await action_loadComboBoxFamilia()
    .then(f => {  
        setFamilias(f);            
    });
  }
//======================================================== CODIGO ARTICULO ================================================
  function handleChangeCodigoArticulo (e) {
    setCodigoArticulo(e.target.value)
  }
//======================================================== DESCRIPCION ARTICULO ================================================
  function handleChangeDescripcionArticulo (e) {
    setDescripcionArticulo(e.target.value)
  }
//======================================================== UTILIDAD ================================================
  function handleChange_Utilidad(e) {
    let valor = e.target.value;

    if (!Number(valor)) { //si no es numerico sale
        return;
    }

    if(parseInt(valor) > parseInt(100)) { //si supero el maximo , sale
        return;
    }

    if(parseInt(valor) < parseInt(1)) { //si supero el maximo , sale
      valor = 20;
    }  

    setUtilidad(valor);
  }

//======================================================== OFERTA ================================================
  function handleChange_Oferta(event) { 
    let snOferta = event.target.checked;
    setSnOferta(snOferta);
  }

//====================================================  LOAD DATA IN REACT-TABLE  =========================================================    
  
  async function handleClickLoadReactTable() {   
    if((idArticulo || marcaSeleccionadaComboBox || familiaSeleccionadaComboBox || codigoArticulo || descripcionArticulo || snOferta) === false) { // NO PASA LA VALIDACION
        alertService.error("Seleccione un filtro"); //MUESTRO ALERTA
      }
      else {
        if(pageIndex === 0) {
          loadData(pageIndex)
        }
        else {
          let _pageIndex = 0;
          setPageIndex(_pageIndex); //AL HACER ESTO SE EJECUTA EL useEffect DE REACT-TABLE
        }
      }
  }

//====================================================  CLEAN UP DATA IN MENU-FILTER  =========================================================    
  
  async function handleClickCleanUpAllMenuFilter() {
    await loadComboBoxMarca();
    await loadComboBoxFamilia();
    
    cleanReactTable();
  }
  

 
  const contentMenuFilter = (anchor) => (
    <Box
      sx={{ width: 'auto' }}
      role="presentation"
    >
        <List>
          <ListItem >
              <div>

                  {/********* TextField ID ARTICULO *********/}
                  {snVisibleIdArticulo &&
                  <>
                    <TextField
                        fullWidth
                        className={classes.textField}
                        variant="outlined"
                        placeholder="Id Articulo"
                        value = {idArticulo}
                        inputProps={{
                            maxLength: 20
                          }}
                        style= {{backgroundColor: 'white'}}
                        onChange={(e) => {handleChangeIdArticuloOnlyNumbers(e)}}
                    />

                    <br/>
                    <br/>
                  </>
                }

                {/********* Autocomplete MARCA *********/}
                <Autocomplete
                  options={marcas}
                  value={marcaSeleccionadaComboBox}
                  getOptionLabel={(option) => option.descripcionMarca || ""}
                  isOptionEqualToValue={(option, value) =>  option.descripcionMarca === value.descripcionMarca }
                  noOptionsText= {<span style={{fontSize: '1.4rem',marginRight: '2px'}}>Sin Resultados</span>}
                  ListboxProps={{
                    sx: { fontSize: '1.4rem' },
                  }}
                  sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                      fontSize: '1.4rem',
                      marginRight: '2px'
                    }
                  }}
                  renderInput={(params) => 
                    <TextField  
                      style={{fontSize: '1.4rem' , backgroundColor: 'white' }}  
                      {...params} 
                      label="Marcas" 
                      variant="filled" />}
                  onChange={onChangeComboBoxMarca}
                />
              
                <br/>
              

                {/********* Autocomplete FAMILIA *********/}  
                <Autocomplete
                  options={familias}
                  value={familiaSeleccionadaComboBox}
                  getOptionLabel={(option) => option.descripcionFamilia || ""}
                  isOptionEqualToValue={(option, value) =>  option.descripcionFamilia === value.descripcionFamilia }
                  noOptionsText= {<span style={{fontSize: '1.4rem',marginRight: '2px'}}>Sin Resultados</span>}
                  ListboxProps={{
                    sx: { fontSize: '1.4rem' },
                  }}
                  sx={{
                    '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                      fontSize: '1.4rem',
                      marginRight: '2px'
                    }
                  }}
                  style={{fontSize: '1.4rem' , backgroundColor: 'white' }} 
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.idTablaFamilia}>
                        {option.descripcionFamilia}
                      </li>
                    );
                  }} 
                  renderInput={(params) => 
                    <TextField  
                      style={{fontSize: '1.4rem' }}  
                      {...params} 
                      label="Familias" 
                      variant="filled" />}

              
                  onChange={onChangeComboBoxFamilia}
                  
                />
              

                <br/>
              

                {/********* TextField CODIGO ARTICULO *********/}
                <TextField
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    placeholder="Codigo Articulo"
                    value = {codigoArticulo}
                    inputProps={{
                        maxLength: 50
                      }}
                    style= {{backgroundColor: 'white'}}
                    onChange={(e) => {handleChangeCodigoArticulo(e)}}
                />
              
          
                <br/>
                <br/>

                {/********* TextField DESCRIPCION ARTICULO *********/}
                <TextField
                    fullWidth
                    className={classes.textField}
                    variant="outlined"
                    placeholder="Descripción"
                    value = {descripcionArticulo}
                    inputProps={{
                        maxLength: 50
                      }}
                    style= {{backgroundColor: 'white'}}
                    onChange={(e) => {handleChangeDescripcionArticulo(e)}}
                />


                <br/>
                <br/>

                {/********* TextField UTILIDAD *********/}  
                {usuario && snVisibleUtilidad &&
                    <>
                      <TextField
                          className={classes.formLabel}
                          fullWidth
                          label= "Utilidad"
                          placeholder="Utilidad"
                          value =  {utilidad}
                          type='number'
                          variant="filled"
                          onChange={(e) => {handleChange_Utilidad(e)}}
                          style= {{backgroundColor: 'white'}}
                      /> 
                      
                      <br/>
                      <br/>
                    </>
                }

               

                {/********* Switch OFERTA *********/}  
                { snVisibleSwitchOferta &&
                  <>
                    <Switch
                        checked={snOferta}
                        onChange={handleChange_Oferta}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}  
                    /> <span style={{fontSize: '1.4rem'}}>Ofertas </span>

                    <br/>
                    <br/>
                  </>
                }


              




                {/********* button BUSCAR *********/}  
                {/********* MIENTRAS BUSCA , VOY A MOSTRAR UN SPINNER EN EL BOTON *********/}  
                <div className={classes.button}>
                    <button 
                      className="btn btn-success"            
                      onClick={() => handleClickLoadReactTable()}
                    >
                      Buscar
                  </button>
                </div>

                 {/********* button LIMPIAR *********/}  
                 <div className={classes.button}>
                    <button 
                      className="btn btn-warning"             
                      onClick={() => handleClickCleanUpAllMenuFilter()}
                    >
                      Limpiar
                  </button>
                </div>
                
                {/********* button CLOSE *********/}  
                <div className={classes.button}>
                  <button 
                    type="submit" 
                    className="btn btn-danger" 
                    onClick={toggleDrawer(anchor, false)}
                  >
                        Cerrar
                  </button>
                </div>

                 

              </div>

             
          </ListItem>
        </List>
                   
    </Box>
  );

  const anchor = "left"
  


//====================================================  STYLE  =========================================================    
  const classes = useStyles();


//====================================================  RENDER  =========================================================      
  return (
    <div>
          <div>
              <div className={classes.button}>
                <button 
                  type="submit" 
                  className="btn btn-success" 
                  onClick={toggleDrawer(anchor, true)} 
                >
                      Busqueda Personalizada
                </button>
              </div>
              <Drawer
                anchor={anchor}
                open={stateContentMenuFilter[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {contentMenuFilter(anchor)}
              </Drawer>
 
          </div>


       
    </div>
  );

}


const mapStateToProps = (state) => {
    return { //cualquier cosa que retorno aca , va a estar disponible como propiedad (props) en nuestro componente
      marcaSelectedHome: state.marcaReducer.marcaSelected ? state.marcaReducer.marcaSelected : null,
    }
  }
  
  
const actionCreators = {
      action_removeSelectedMarca: marcaActions.removeSelectedMarca,
      action_loadComboBoxFamilia: familiaActions.loadComboBoxFamilia,
      action_loadComboBoxMarca: marcaActions.loadComboBoxMarca,
      action_loadComboBoxFamiliaByMarca: familiaActions.loadComboBoxFamiliaByMarca
}
    
export default connect(mapStateToProps, actionCreators)(MenuFilter);


