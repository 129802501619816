import { fetchWrapper } from '@helpers';
const baseUrl = `/usuarioCuentaCorriente`;


export const usuarioCuentaCorrienteService = {
    getAllActive  
};


function getAllActive(params) {
    return fetchWrapper.post(`${baseUrl}/get-all-active`, params );
}








