import React,{useState , useRef } from "react";
import { articuloActions } from '@actions';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import ButtonCustom from '@buttonCustom';
import {
  CircularProgress
} from '@mui/material';

function EditarPage(props) {
  const { 
    modificarImagenArticulo, 
    setSnVisibleEditarImagenArticulo,
    rowSelected, 
    snLocal
  } = props;

  const [imagenCargada, setImagenCargada] = useState(false);
  const ref = useRef();
  
  //rowSelected -> viene de <ImagenesArticulosListPage/>)
    const [pathImg, setPathImg] = useState(rowSelected.pathImagenArticulo ? rowSelected.pathImagenArticulo + '?' + Date.now() : '/assets/images/imagenes_articulos/sin_imagen.png' + '?' + Date.now())

  //======================================================== VOLVER ================================================
  const handleClickVolver = () => {
    setSnVisibleEditarImagenArticulo(false) //vuelvo a dashboard
  };


  //======================================================== ON SUBMIT ================================================
  function onSubmit({ fileImagenArticulo }, { setSubmitting }) {
    var formData = new FormData();
    formData.append('idArticulo', rowSelected.id);
    formData.append('snLocal', snLocal); //EL FILE LO VOY A SUBIR A MI PC LOCAL (1) O LO SUBO EN EL SERVIDOR (0)
    formData.append('nameFileOld', rowSelected.pathImagenArticulo); 
    formData.append('fileNew', fileImagenArticulo);

    modificarImagenArticulo(formData)
    .then((articuloModificado) => {
      setSubmitting(false)
      setPathImg(articuloModificado.pathImg + '?' + Date.now())

    })
    .catch(() => 
      setSubmitting(false)
    )
    
  }

   //======================================================== VALIDATION ================================================
  const validationSchema = Yup.object().shape({
    fileImagenArticulo: Yup
                  .mixed()
                  .required('El archivo es requerido')
                  .test("FILE_SIZE", "El archivo subido es demasiado grande , no puede superar los 100KB.", 
                                value => !value || (value && value.size <= 102400))
                  .test("type", "Solo se acepta el siguiente formato: png", (value) => {
                    return value && (
                        value.type === "image/png"
                    );
                }),
  });

  //======================================================== IMAGEN ACTUAL ARTICULO ================================================
  function  handleImageLoaded() {
    if (!imagenCargada) { 
        setImagenCargada(true)
        
    }
  }



  return (
      <div>
         

          <br/>
          <br/>

          <Formik initialValues={{ fileImagenArticulo: null }} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ errors, touched, isSubmitting, setFieldValue }) => (
                  <Form>
                    <h3 className="card-header">Editar Imagen - Id Articulo: {rowSelected.id}</h3>  
                    <p className="subir_imagenen_articulo_aclaracion">*La imagen debe ser en formato PNG</p>          
                    <p className="subir_imagenen_articulo_aclaracion">*La imagen no puede superar los 100KB</p>
                    <p className="subir_imagenen_articulo_aclaracion">*La imagen no puede superar los 250px de ancho o largo</p>          
                      <div className="card-body">
                          <div className="form-group">
                                
                              <div className="subir_imagenen_articulo_photo">
                                  <img
                                      src={pathImg}
                                      onLoad={handleImageLoaded}
                                      loading="lazy"
                                      style= {{
                                          height: !imagenCargada ? '0' : '100%', //si no esta cargada la imagen , pongo el height en 0
                                          width: !imagenCargada ? '0' : '100%',  //si no esta cargada la imagen , pongo el width en 0
                                          objectFit: 'contain'
                                      }} 
                                  />
                                  {!imagenCargada && ( //si no esta cargada la imagen muestro esto....
                                        <div className="image-container-overlay" style={{display: 'flex', justifyContent: 'center'}}>
                                            <CircularProgress />
                                        </div>
                                  )}
                                </div>

                                <input 
                                  name="fileImagenArticulo"  
                                  type="file"  
                                  className={'form-control' + (errors.fileImagenArticulo && touched.fileImagenArticulo ? ' is-invalid' : '')} 
                                  style={{border: 0}}
                                  accept="image/png"
                                  onChange={(event) => {
                                    setFieldValue("fileImagenArticulo", event.currentTarget.files[0]);
                                  }}
                                  ref={ref}
                                />
                                <ErrorMessage name="fileImagenArticulo" component="div" className="invalid-feedback" />

                                <br/>

                                <div className="form-row">
                                  <div className="form-group col">
                                      <ButtonCustom  
                                        type="submit" 
                                        isSubmitting={isSubmitting} 
                                        text="Subir Imagen Artículo"
                                      />          
                                  </div>
                                  <div className="form-group col text-right">
                                      <ButtonCustom  
                                        type="button" 
                                        isSubmitting={isSubmitting} 
                                        text="Volver"
                                        onClick={handleClickVolver}
                                        className="btn btn-danger"
                                      />      
                                  </div>
                              </div>    
                            
                          </div>
                      </div>
                  </Form>
                )}
          </Formik>
      </div>
  );
}

const actionCreators = {
  modificarImagenArticulo: articuloActions.modificarImagenArticulo
};



export default connect(null, actionCreators)(EditarPage);
