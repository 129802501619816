import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import { mobileMenu } from '@utils';
import {rol} from '@helpers'
import AdminMenu from './admin-menu'

function MobileMainNav(props ) {

    const {usuario} = props;
    
    useEffect( () => {
        mobileMenu();
    }, [] )

   
    return (
        <nav className="mobile-nav">
            <ul className="mobile-menu">
                <li>
                    <Link to={ `${process.env.PUBLIC_URL}` }>
                        Inicio
                    </Link>
                </li>

                

                <li>
                    <Link to={ `${process.env.PUBLIC_URL}/catalogo/list` }>
                        Catalogo
                    </Link>
                </li>

                {
                    usuario && usuario.rol === rol.Admin && <AdminMenu  />

                }

                

            </ul>
        </nav>
    );
}

export default MobileMainNav;