import {  usuarioPedidoConstantes } from '@actions/types';


const initialState = {
    idUsuario: 0,           //id usuario
    callGetPedidoNotFinalizedByIdUsuario: false, //esta variable indica si se debe llamar a "usuarioPedidoActions.getPedidoByIdUsuarioNotFinalized"

    usuarioPedidos: [],    //todos los pedidos del usuario

    totalPedidoDetalles: 0, //cantidad de detalles del pedido
    pedidoDetalles: [],    //detalles del pedido

    totalPedidos_All: 0, //cantidad de pedidos de todos los usuarios
    pedidos_All: [], //todos los pedidos de todos los usuarios
}

export default function usuarioPedidoReducer( state = initialState, action ) {
    switch ( action.type ) {
        case usuarioPedidoConstantes.AGREGAR_ARTICULO_PEDIDO_SUCCESS:
                return {
                    idUsuario: action.articuloAgregado.idUsuario,
                    callGetPedidoNotFinalizedByIdUsuario: true //agrege un articulo , por ende llamo a "usuarioPedidoActions.getPedidoByIdUsuarioNotFinalized"
                };
        case usuarioPedidoConstantes.ELIMINAR_ARTICULO_PEDIDO_SUCCESS:
                return {
                    idUsuario: action.articuloEliminado.idUsuario,
                    callGetPedidoNotFinalizedByIdUsuario: true //elimine un articulo , por ende llamo a "usuarioPedidoActions.getPedidoByIdUsuarioNotFinalized"
                };
        case usuarioPedidoConstantes.MODIFICAR_ARTICULO_PEDIDO_SUCCESS:
                return {
                    idUsuario: action.articuloModificado.idUsuario,
                    callGetPedidoNotFinalizedByIdUsuario: true //modifique un articulo , por ende llamo a "usuarioPedidoActions.getPedidoByIdUsuarioNotFinalized"
                };
        case usuarioPedidoConstantes.GET_PEDIDO_BY_ID_USUARIO_NOT_FINALIZED_SUCCESS:
                return {
                    idUsuario: action.payload.idUsuario,
                }; 
        case usuarioPedidoConstantes.GET_PEDIDOS_ALL_SUCCESS:
            return {
                totalPedidos_All: action.usuarioPedidos_All.total,
                pedidos_All: action.usuarioPedidos_All.datos,
            }; 
        case usuarioPedidoConstantes.GET_PEDIDOS_BY_ID_USUARIO_SUCCESS:
            return {
                usuarioPedidos: action.usuarioPedido,
            };   
        case usuarioPedidoConstantes.MODIFICAR_SNYALOPREPARE:
            return {
                usuarioPedidos: action.usuarioPedido,
            };     
        case usuarioPedidoConstantes.GET_PEDIDO_DETALLES_BY_ID_USUARIO_PEDIDO_SUCCESS:
            return {
                totalPedidoDetalles: action.usuarioPedidoDetalles.total,
                pedidoDetalles: action.usuarioPedidoDetalles.datos,
            };   
        case usuarioPedidoConstantes.FINALIZAR_PEDIDO_SUCCESS:
            return {
                callGetPedidoNotFinalizedByIdUsuario: true //finalice pedido , por ende llamo a "usuarioPedidoActions.getPedidoByIdUsuarioNotFinalized"
            };
     
        default:
            return state;
    }
}
