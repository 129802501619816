import React, { useState} from 'react';
import {  usuarioCuentaCorrienteActions } from '@actions';
import { connect } from 'react-redux';
import * as moment from 'moment';
import { usuarioService } from '@services'
import ReactTable from "../../../../common/react-table";

function UsuarioCuentaCorrientePage(props) {
  
  const {
    getAllActive
  } = props

  const usuario = usuarioService.usuarioValue;
  const [rows, setRows] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const rowsPerPage = 10;


  //la primera carga del componente se hace desde ReactTable
  
  const valueFormatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });



  const columns = [
    {
      Header: 'id',
      accessor: 'id',
      show: false
    },
    {
      Header: 'Nro Comprobante',
      accessor: 'nroComprobante',
      show: true,
      width: 170
    },
    {
      Header: 'Fecha',
      accessor: 'fecha',
      Cell: props => {
        if(props.value !== null) {
          return  moment(props.value).format('DD/MM/yyyy')
        }

       
      } ,
      show: true,
      width: 110
    },
    {
      Header: 'Estado',
      accessor: 'estado',
      show: true,
      width: 167
    },
    {
      Header: 'Total Boleta',
      accessor: 'saldo',
      Cell: props => valueFormatter.format(props.value),
      show: true,
      width: 115
    },
    {
      Header: 'Saldo Acum.',
      accessor: 'sAcum',
      Cell: props => valueFormatter.format(props.value),
      show: true,
      width: 110
    },
    {
      Header: 'Observacion Movimiento Cta Cte',
      accessor: 'observacionMovimientoCtaCte',
      show: true,
      width: 257
    },
    {
      Header: 'cuit',
      accessor: 'cuit',
      show: false
    }
  ];


   function loadData (pageIndex) {

        const payload = {
          cuit: usuario.cuit,
          skip: pageIndex,
          take: rowsPerPage
        }

        getAllActive(payload)
        .then(newRows => {
          setRows(newRows);
          setPageCount(Math.ceil(newRows.total / rowsPerPage))
        })
        .catch(() => {
          setRows(null);
          setPageCount(0)
        })
      }
  



  return (


    <div> 

      <p className="text-danger">* Registro de los ultimos 2 meses</p>
      <br/>
      <ReactTable
        columnsTable={columns}
        data={rows ? rows.datos : []}
        dataAux={rows}
        onSelectRowReactTable = {undefined}
        viewRowFilters = {false}
        pageCount={pageCount}
        loadData = {loadData}
        pageIndex = {pageIndex}
        setPageIndex = {setPageIndex}

      />
    </div>
  );
}

const actionCreators = {
  getAllActive: usuarioCuentaCorrienteActions.getAllActive
}
  
export default connect(null, actionCreators)(UsuarioCuentaCorrientePage);


