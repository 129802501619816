import React,{useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';
import ButtonCustom from '@buttonCustom';

function ErrorPage404() {

    useEffect( () => {  
        document.querySelector( 'body' ).classList.add( "loaded" ); //esto lo agrego ya que no llamo en este componente a reactTable , y si no pongo esto queda corriendo los puntitos rojos indefinidamente
    } )


    return (
        <div>
            <Helmet>
                <title>Encendido Alsina - 404</title>
            </Helmet>
            
            <div className="main">
                <Breadcrumb parent1={ [ "404", "error-404" ] } adClass="mb-2" />


                <div className="error-content text-center" style={ { backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/backgrounds/error-bg-1.jpg)` } }>
                    <div className="container">
                        <h1 className="error-title">Error 404</h1>

                        <p>Lo sentimos, la página que ha solicitado no está disponible..</p>
                        <Link to={ `${process.env.PUBLIC_URL}/` }>
                            <ButtonCustom 
                                type="button"
                                text= {
                                <div>
                                    <span>VOLVER AL INICIO</span>
                                    <i className="icon-long-arrow-right"></i>
                                </div>
                                }
                            />      
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo( ErrorPage404 );
