
import { fetchWrapper } from '@helpers';
const baseUrl = `/sincronizarTabla`;


export const sincronizarTablaService = {
    sincronizarTabla
};


function sincronizarTabla() {
    return fetchWrapper.post(`${baseUrl}/sincronizar-tabla`);
}

