import React from 'react';
import { Link } from 'react-router-dom';
import {rol} from '@helpers'
import AdminMenu from './admin-menu'

export default function MainMenu(props) {
    const {usuario} = props;
    return (

        
        <nav className="main-nav">
        <ul className="menu sf-arrows">
            <li className={ `megamenu-container` } id="menu-home">
                <Link  to={ `${process.env.PUBLIC_URL}` } className="sf-with">Inicio</Link>
            </li>

        
            <li className={ `megamenu-container` } id="menu-home">
                <Link  to={ `${process.env.PUBLIC_URL}/catalogo/list` } className="sf-with">Catalogo</Link>
            </li>

            {
                usuario && usuario.rol === rol.Admin && <AdminMenu  />
            }
        </ul>
    </nav>
    );
}