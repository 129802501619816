import React, { useState , useEffect} from 'react';
import ArticuloList from './articuloList';
import ArticuloList2 from './articuloList2';
import { connect } from 'react-redux';
import { articuloActions , usuarioPedidoActions } from '@actions';
import { usuarioService } from '@services';
import SearchIcon from '@mui/icons-material/Search';
import {
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';
import {  makeStyles } from '@mui/styles';
import MenuFilter from './menuFilter';

function Articulo(props) {

  const {
          column,
          vengoDeImagenesArticulos,
          snVisibleIdArticulo,
          snVisibleUtilidad,
          snVisibleSwitchOferta,
          viewRowFilters,
          columnsArticuloList,
          snVisibleDescripcionFila,

          empresa,
          marcaSelectedHome,

          getArticulosByFilters,
          agregarArticuloPedido
        } = props


  const usuario = usuarioService.usuarioValue;

  //filas de articuloList y articuloList2
  const [rows, setRows] = useState(null);
  
  const [cantidad,setCantidad] = useState(1);
  
  const [cols,setCols] = useState( column );
  
  //esto esta a nivel general para articuloList y articuloList2 , por ende el pageCount y pageIndex aplica para ambas vistas
  //por ejemplo si en articuloList estoy en pageIndex 5 , en articuloList2 tmb voy a estar en pageIndex 5
  const rowsPerPage = 10; //si quiero mostrar mas o menos filas por pagina solamente debo modificar aca
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);

  const [idArticulo, setIdArticulo] = useState("");
  const [marcaSeleccionadaComboBox, setMarcaSeleccionadaComboBox] = useState( 
                                                                              (!vengoDeImagenesArticulos && marcaSelectedHome) 
                                                                              ? {
                                                                                  descripcionMarca: marcaSelectedHome.descripcionMarca,
                                                                                  list_IdTablaMarca: marcaSelectedHome.list_IdTablaMarca
                                                                                }
                                                                              :
                                                                                null
                                                                            )
  const [familiaSeleccionadaComboBox, setFamiliaSeleccionadaComboBox] = useState(null);
  const [codigoArticulo, setCodigoArticulo] = useState("");
  const [descripcionArticulo, setDescripcionArticulo] = useState("");
  const [snOferta, setSnOferta] = useState(false);
  const [utilidad,setUtilidad] = useState(usuario ? usuario.utilidad : 20); //seteo la utilidad del usuario
  const [searchFilterGlobal, setSearchFilterGlobal] = useState("");
  
  const [showArticuloInfoList,setShowArticuloInfoList] = useState(false);
  const [showArticuloInfoList2,setShowArticuloInfoList2] = useState(false);
  
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
 
  //la primera carga del componente se hace desde ReactTable
  
  const useStyles = makeStyles({
    textField: {
          "& .MuiInputBase-root":{
            fontSize: '1.4rem'
          }
       }
  });

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

//======================================================== SHOW ARTICULO INFO ================================================
  function initShowArticuloInfo () {
    setShowArticuloInfoList(false)
    setShowArticuloInfoList2(false)
  }

//======================================================== COLUMN ================================================
  function onClickBtnLayout(valueCol) {
    setShowArticuloInfoList(false)
    setShowArticuloInfoList2(false)
    setCols(valueCol);
  }

//======================================================== CLEAN DATA ================================================
function cleanReactTable () {

  let _pageIndex = 0;
  let _idArticulo = "";
  let _marcaSeleccionadaComboBox = null;
  let _familiaSeleccionadaComboBox = null;
  let _codigoArticulo = "";
  let _descripcionArticulo = "";
  let _snOferta = false;
  let _utilidad = usuario ? usuario.utilidad : 20
  let _searchFilterGlobal = "";

  setIdArticulo(_idArticulo);
  setMarcaSeleccionadaComboBox(_marcaSeleccionadaComboBox);
  setFamiliaSeleccionadaComboBox(_familiaSeleccionadaComboBox);
  setCodigoArticulo(_codigoArticulo);
  setDescripcionArticulo(_descripcionArticulo);
  setSnOferta(_snOferta);
  setUtilidad(_utilidad);
  setSearchFilterGlobal(_searchFilterGlobal);
  initShowArticuloInfo();


  if(pageIndex === 0) {
    loadDataWhenClean( 
              _pageIndex,
              _idArticulo,
              _marcaSeleccionadaComboBox,
              _familiaSeleccionadaComboBox,
              _codigoArticulo,
              _descripcionArticulo,
              _utilidad,
              _snOferta,
              _searchFilterGlobal
            );
  }
  else {
    setPageIndex(_pageIndex); //AL HACER ESTO SE EJECUTA EL useEffect DE REACT-TABLE
  }
}

//======================================================== LOAD DATA ================================================

async function loadDataWhenClean (pageIndex,idArticulo,marcaSeleccionadaComboBox,
                                  familiaSeleccionadaComboBox,codigoArticulo,descripcionArticulo,
                                  utilidad,snOferta,searchFilterGlobal) {
  let active = true;
  (async () => { 
    await getArticulosByFilters(pageIndex,
                                rowsPerPage,
                                idArticulo,
                                marcaSeleccionadaComboBox,
                                familiaSeleccionadaComboBox,
                                codigoArticulo,
                                descripcionArticulo,
                                utilidad,
                                snOferta,
                                searchFilterGlobal)

    .then(newRows => {
      if (!active) {
        return;
      }

      initShowArticuloInfo();

      setRows(newRows)
      setPageCount(Math.ceil(newRows.total / rowsPerPage))
    })
    .catch(() => {
      initShowArticuloInfo();
      setRows(null);
      setPageCount(0)
    })
  })();

  return () => {
    active = false;
  };
}

async function loadData (pageIndex) {
  let active = true;
  (async () => { 
    await getArticulosByFilters(pageIndex,
                                rowsPerPage,
                                idArticulo,
                                marcaSeleccionadaComboBox,
                                familiaSeleccionadaComboBox,
                                codigoArticulo,
                                descripcionArticulo,
                                utilidad,
                                snOferta,
                                searchFilterGlobal)

    .then(newRows => {
      if (!active) {
        return;
      }

      initShowArticuloInfo();

      setRows(newRows)
      setPageCount(Math.ceil(newRows.total / rowsPerPage))
    })
    .catch(() => {
      initShowArticuloInfo();
      setRows(null);
      setPageCount(0)
    })
  })();

  return () => {
    active = false;
  };
}

//====================================================  ADD ARTICULO A PEDIDO =========================================================    
function onClick_IconCart(selectedRowsData) //la funcion "onClick_IconCart" es llamada por el Children <DescripcionFila/>
{
  if ( 0 !== selectedRowsData.stockArticulo )
    agregarArticuloPedido( selectedRowsData, cantidad , usuario.idUsuario , empresa.idEmpresa , utilidad );
}


//====================================================  SEARCH FILTER GLOBAL =========================================================    
const handleChangeTextField = (e) => {
  setSearchFilterGlobal(e.target.value)
};

const handleKeyDownTextField = (e) => {
  if(e.keyCode == 13){
    actionSearchFilterGlobal();
  }
};

function handleClickButtonTextField () {
  actionSearchFilterGlobal();
}

function actionSearchFilterGlobal() {
  if(pageIndex === 0) {
    loadData(pageIndex)
  }
  else {
    let _pageIndex = 0;
    setPageIndex(_pageIndex); //AL HACER ESTO SE EJECUTA EL useEffect DE REACT-TABLE
  }
}


//====================================================  STYLE  =========================================================    
const classes = useStyles();

//====================================================  RENDER  =========================================================    
  return (
    <div>
      <div className="container">
        <div className="row">
          {
            !isMobile &&
            <div style={{display: 'inline', width: '20%'}}>
              <MenuFilter    
                loadData = {loadData}
                cleanReactTable = {cleanReactTable}
                
                pageIndex = {pageIndex}
                setPageIndex = {setPageIndex}

                idArticulo = {idArticulo}
                setIdArticulo = {setIdArticulo}
                marcaSeleccionadaComboBox = {marcaSeleccionadaComboBox}
                setMarcaSeleccionadaComboBox = {setMarcaSeleccionadaComboBox}
                familiaSeleccionadaComboBox = {familiaSeleccionadaComboBox}
                setFamiliaSeleccionadaComboBox = {setFamiliaSeleccionadaComboBox}
                codigoArticulo = {codigoArticulo}
                setCodigoArticulo = {setCodigoArticulo}
                descripcionArticulo = {descripcionArticulo}
                setDescripcionArticulo = {setDescripcionArticulo}
                snOferta = {snOferta}
                setSnOferta = {setSnOferta}
                utilidad = {utilidad}
                setUtilidad = {setUtilidad}
                
                snVisibleIdArticulo = {snVisibleIdArticulo}
                snVisibleUtilidad = {snVisibleUtilidad}
                snVisibleSwitchOferta = {snVisibleSwitchOferta}
              
              />
            </div>
          }
          
          <div style={{display: 'inline', width: '70%'}}>
            {/********* TextField SEARCH FILTER GLOBAL *********/}
            <div className='toolbox-middle'>
              {
                  !vengoDeImagenesArticulos && rows && 
                  <div>
                    <div>
                    <TextField
                      fullWidth
                      value= {searchFilterGlobal}
                      className={classes.textField}
                      variant="outlined"
                      placeholder="Buscar..."
                      inputProps={{
                          maxLength: 50
                        }}
                      style= {{backgroundColor: 'white'}}
                      onChange={(e) => {handleChangeTextField(e)}}
                      onKeyDown={(e) => {handleKeyDownTextField(e)}}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={() =>handleClickButtonTextField()}
                            >
                              <SearchIcon fontSize="large" color="primary"/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    </div>
                  </div>
              }
            </div>
          </div>
          
          <div style={{display: 'inline', width: isMobile ? "30%" : '10%' , textAlign: 'center'}}>
            <button className={ `btn-layout ${'list' === cols ? 'active' : ''}` } onClick={() => onClickBtnLayout('list')}>
              <svg width="10" height="10">
                    <rect x="0" y="0" width="4" height="4"></rect>
                    <rect x="6" y="0" width="4" height="4"></rect>
                    <rect x="0" y="6" width="4" height="4"></rect>
                    <rect x="6" y="6" width="4" height="4"></rect>
                </svg> 
            </button>

            {
              !vengoDeImagenesArticulos && rows && 
                <button className={ `btn-layout ${'list2' === cols ? 'active' : ''}` } onClick={() => onClickBtnLayout('list2')}>
                <svg width="16" height="10">
                    <rect x="0" y="0" width="4" height="4"></rect>
                    <rect x="0" y="0" width="10" height="4"></rect>
                    <rect x="0" y="6" width="4" height="4"></rect>
                    <rect x="0" y="6" width="10" height="4"></rect>
                </svg>
              </button>
            }
          </div>
        </div>
      </div>

      <br/>
        
      {
        cols === 'list'  ?
                          <ArticuloList
                            usuario = {usuario}       
                            rows = {rows}              
                            cantidad = {cantidad}
                            setCantidad = {setCantidad}
                            onClick_IconCart = {onClick_IconCart}
                            viewRowFilters = {viewRowFilters}
                            columnsArticuloList = {columnsArticuloList}
                            showArticuloInfoList = {showArticuloInfoList}
                            setShowArticuloInfoList = {setShowArticuloInfoList}
                            snVisibleDescripcionFila = {snVisibleDescripcionFila}
                            pageCount = {pageCount}
                            loadData = {loadData}
                            pageIndex = {pageIndex}
                            setPageIndex = {setPageIndex}
                          />
                        :
                          <ArticuloList2
                            usuario = {usuario}
                            data={rows ? rows.datos : []}
                            dataAux={rows}
                            cantidad = {cantidad}
                            setCantidad = {setCantidad}
                            onClick_IconCart = {onClick_IconCart}
                            showArticuloInfoList2 = {showArticuloInfoList2}
                            setShowArticuloInfoList2 = {setShowArticuloInfoList2}
                            snVisibleDescripcionFila = {snVisibleDescripcionFila}
                            pageCount = {pageCount}
                            loadData = {loadData}
                            pageIndex = {pageIndex}
                            setPageIndex = {setPageIndex}
                          />
      }

    </div>
  );

}


const mapStateToProps = (state) => {
  return { //cualquier cosa que retorno aca , va a estar disponible como propiedad (props) en nuestro componente
    empresa: state.empresaReducer.empresa,
    marcaSelectedHome: state.marcaReducer.marcaSelected ? state.marcaReducer.marcaSelected : null,
  }
}

const actionCreators = {
    getArticulosByFilters: articuloActions.getByFilters,
    agregarArticuloPedido: usuarioPedidoActions.agregarArticuloPedido
  }
  
export default connect(mapStateToProps, actionCreators)(Articulo);
