
import { downloadCatalogoConstantes } from './types';
import { downloadCatalogoService , alertService } from '@services';


export const downloadCatalogoActions = {
    getAllActive,
    agregarDownloadCatalogo,
    eliminarDownloadCatalogo,
    modificarDownloadCatalogo,
};


function agregarDownloadCatalogo(formData) {
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );
        
        return new Promise((resolve, reject) => {
            downloadCatalogoService.agregarDownloadCatalogo(formData)
                    .then(
                        downloadCatalogoAgregado => { 

                            dispatch( success(downloadCatalogoAgregado));
                            alertService.success('Catálogo agregado correctamente');
                            resolve(downloadCatalogoAgregado); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                        .finally(() => {
                            document.querySelector( 'body' ).classList.add( "loaded" );
                        });
            })
        };

        function success(downloadCatalogoAgregado) { return { type: downloadCatalogoConstantes.AGREGAR_DOWNLOAD_CATALOGO_SUCCESS,  downloadCatalogoAgregado } }
}


function eliminarDownloadCatalogo(payload) {
   
    return dispatch => {
  
        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            downloadCatalogoService.eliminarDownloadCatalogo(payload)
                    .then(
                        downloadCatalogoEliminado => { 
                            dispatch( success(downloadCatalogoEliminado));
                            alertService.success('Catálogo eliminado correctamente');            
                            resolve(downloadCatalogoEliminado); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                        .finally(() => {
                            document.querySelector( 'body' ).classList.add( "loaded" );
                        });
            })
        };


        function success(downloadCatalogoEliminado) { return { type: downloadCatalogoConstantes.ELIMINAR_DOWNLOAD_CATALOGO_SUCCESS,  downloadCatalogoEliminado } }

}


function modificarDownloadCatalogo(formData) {
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            downloadCatalogoService.modificarDownloadCatalogo(formData)
                    .then(
                        downloadCatalogoModificado => { 
                            dispatch( success(downloadCatalogoModificado));
                            alertService.success('Catálogo editado correctamente');
                            resolve(downloadCatalogoModificado); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                        .finally(() => {
                            document.querySelector( 'body' ).classList.add( "loaded" );
                        });
            })
        };

    
        function success(downloadCatalogoModificado) { return { type: downloadCatalogoConstantes.MODIFICAR_DOWNLOAD_CATALOGO_SUCCESS,  downloadCatalogoModificado } }

}


function getAllActive(pageIndex,rowsPerPage) {
    const payload = {
        skip: pageIndex,
        take: rowsPerPage
    };

    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            downloadCatalogoService.getAllActive(payload)
                    .then(
                        downloadCatalogoGetAll => { 
                            dispatch( success(downloadCatalogoGetAll));
                            
                            resolve(downloadCatalogoGetAll); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                    //document.querySelector( 'body' ).classList.add( "loaded" ); -> se agrega en useEffect en ReactTable
            })
        };

        function success(downloadCatalogoGetAll) { return { type: downloadCatalogoConstantes.GET_ALL_ACTIVE_SUCCESS_DOWNLOADCATALOGO,  downloadCatalogoGetAll } }
}

