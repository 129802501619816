
import { combineReducers } from "redux";

import alertaReducer from "./alertReducer/alert.reducers";

import loginReducer from "./usuarioReducer/loginReducer.reducers";
import registerReducer from "./usuarioReducer/register.reducers";
import verifyUserReducer from "./usuarioReducer/verifyUser.reducers";
import forgotPasswordReducer from "./usuarioReducer/forgotPassword.reducers"
import validateResetToken from "./usuarioReducer/validateResetToken.reducers"
import resetPassword from "./usuarioReducer/resetPassword.reducers"
import getByIdReducer from "./usuarioReducer/getById.reducers"
import updateReducer from "./usuarioReducer/update.reducers"

import usuarioPedidoReducer from "./usuarioPedidoReducer/usuarioPedido.reducers"
import marcaReducer from "./marcaReducer/marca.reducers.js"
import familiaReducer from "./familiaReducer/familia.reducers.js"
import empresaReducer from "./empresaReducer/empresa.reducers.js"
import articuloReducer from "./articuloReducer/articulo.reducers.js"

import downloadCatalogoReducer from "./downloadCatalogoReducer/downloadCatalogo.reducers.js"

import sincronizarTablaReducer from "./sincronizarTablaReducer/sincronizarTabla.reducers"

import dropDownCartMenuReducer from "./dropDownCartMenuReducer/dropDownCartMenu.reducers"

export default combineReducers({
    alertaReducer,
    loginReducer,
    registerReducer,
    verifyUserReducer,
    forgotPasswordReducer,
    validateResetToken,
    resetPassword,
    getByIdReducer,
    updateReducer,
    usuarioPedidoReducer,
    marcaReducer,
    familiaReducer,
    empresaReducer,
    articuloReducer,
    downloadCatalogoReducer,
    sincronizarTablaReducer,
    dropDownCartMenuReducer
});