import {  downloadCatalogoConstantes } from '@actions/types';


const initialState = {
    downloadCatalogo : []
}

export default function downloadCatalogoReducer( state = initialState, action ) {
    switch ( action.type ) {
        case downloadCatalogoConstantes.AGREGAR_DOWNLOAD_CATALOGO_SUCCESS:
            return [state, action.downloadCatalogoAgregado];
        case downloadCatalogoConstantes.ELIMINAR_DOWNLOAD_CATALOGO_SUCCESS:
            return state.datos.filter(({ idDownloadCatalogo }) => idDownloadCatalogo !== action.downloadCatalogoEliminado.idDownloadCatalogo);
        case downloadCatalogoConstantes.MODIFICAR_DOWNLOAD_CATALOGO_SUCCESS:
            return state.datos.map((downloadCatalogo) => {
                if (downloadCatalogo.idDownloadCatalogo === action.downloadCatalogoModificado.idDownloadCatalogo) {
                  return {
                    ...downloadCatalogo,
                    ...action.downloadCatalogoModificado,
                  };
                } else {
                  return downloadCatalogo;
                }
              });
        case downloadCatalogoConstantes.GET_ALL_ACTIVE_SUCCESS_DOWNLOADCATALOGO:
                return action.downloadCatalogoGetAll
        default:
            return state;
    }
}
