import React, { useState, useEffect } from 'react';

import ImagenesArticulosListPage from "./list";
import EditarPage from "./editar";
import './index.css'
import { Helmet } from 'react-helmet';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';

export default function ImagenesArticulosPage() {
  
  const [rowSelected , setRowSelected] = useState([]);
  const [snLocal,setSnLocal] = useState(1);
  const [snVisibleEditarImagenArticulo,setSnVisibleEditarImagenArticulo] = useState(false);

  useEffect( () => {
   
    //EL FILE LO VOY A SUBIR A MI PC LOCAL (1) O LO SUBO EN EL SERVIDOR (0)
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      setSnLocal(1)
    }
    else {
      setSnLocal(0)
    }   
  }, []);


  
  return (

    <div>
      <Helmet>
          <title>Encendido Alsina - Admin Imagenes Articulos</title>
      </Helmet>
      
      <h1 className="d-none">Encendido Alsina - Admin Imagenes Articulos</h1>

      <div className="main">
          <Breadcrumb  parent1={ [ "Admin Imagenes Articulos", "imagenes_articulos" ] } adClass="mb-2" />

          <div className="page-content">
              <div className="container">
                  <div>
                      {
                        snVisibleEditarImagenArticulo 
                                                ? <EditarPage 
                                                      rowSelected = {rowSelected}
                                                      snLocal= {snLocal}
                                                      setSnVisibleEditarImagenArticulo = {setSnVisibleEditarImagenArticulo}
                                                  />
                                                : <div>
                                                      <ImagenesArticulosListPage
                                                          grid = {"list"}
                                                          setRowSelected = {setRowSelected}
                                                          setSnVisibleEditarImagenArticulo = {setSnVisibleEditarImagenArticulo}
                                                      />   
                                                  </div>
                                                
                      }
                  </div>
              </div>
          </div>
        </div>
      </div>

    
  );
}