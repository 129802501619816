import React,{useEffect} from 'react';
import { Route, Switch} from 'react-router-dom';
import LoadingOverlay from '../components/features/loading-overlay';
import Layout from '../components/app';
import { scrollTop } from '@utils';
import {Alert} from '../components/alert'


import CarritoCompraPage from '../components/pages/carritoCompra';
import PedidosAllPage from '../components/pages/pedidos_all';
import SincronizarTablaPage from '../components/pages/sincronizar_tabla'
import CatalogoPage from '../components/pages/catalogo';
import ErrorPage404 from '../components/pages/error/404';
import HomePage from '../components/pages/home';
import ImagenesArticulosPage from '../components/pages/imagenes_articulos';
import DownloadCatalogosPage from '../components/pages/download_catalogos'
import AdminUsersInactivesPage from '../components/pages/admin_users_inactives'

import DashboardPage from '../components/pages/usuario/dashboard';
import ForgotPasswordPage  from '../components/pages/usuario/forgot_password';
import LoginPage  from '../components/pages/usuario/login';
import Register_UpdateUserPage  from '../components/pages/usuario/register-updateUser';
import ResetPasswordPage  from '../components/pages/usuario/reset_password';
import VerifyUserPage  from '../components/pages/usuario/verify_user';









import { PrivateRouteUser } from './private_route_user';
import { PrivateRouteAdmin } from './private_route_admin';




function AppRoot() {

   

    useEffect( () => {
        scrollTop();
    
    }, [] )

    return (
        <React.Suspense fallback={ <LoadingOverlay /> }>
            <Alert />
                <Switch>
                    <Layout>
                        <Switch>
                            <Route exact  path={ `${process.env.PUBLIC_URL}/` } component={ HomePage } />

                            <Route exact path={ `${process.env.PUBLIC_URL}/catalogo/:grid` } component={ CatalogoPage } />

                            <PrivateRouteUser exact path={ `${process.env.PUBLIC_URL}/carrito_compra` } component={ CarritoCompraPage } />

                            <PrivateRouteAdmin exact path={ `${process.env.PUBLIC_URL}/get_pedidos_all` } component={ PedidosAllPage } />
                            <PrivateRouteAdmin exact path={ `${process.env.PUBLIC_URL}/sincronizar_tabla` } component={ SincronizarTablaPage } />
                            <PrivateRouteAdmin exact path={ `${process.env.PUBLIC_URL}/imagenes_articulos/:grid` } component={ ImagenesArticulosPage } />
                            <PrivateRouteAdmin exact path={ `${process.env.PUBLIC_URL}/download_catalogos` } component={ DownloadCatalogosPage } />
                            <PrivateRouteAdmin exact path={ `${process.env.PUBLIC_URL}/admin_users_inactives` } component={ AdminUsersInactivesPage } />

                            <PrivateRouteUser exact path={ `${process.env.PUBLIC_URL}/usuario/dashboard` } component={ DashboardPage } />
                            <Route exact path={ `${process.env.PUBLIC_URL}/usuario/forgot-password`} component={ForgotPasswordPage} />
                            <Route exact path={ `${process.env.PUBLIC_URL}/usuario/login` } component={ LoginPage } />
                            <Route exact path={ `${process.env.PUBLIC_URL}/usuario/register`} component={Register_UpdateUserPage} />
                            <Route exact path={ `${process.env.PUBLIC_URL}/usuario/reset-password`} component={ResetPasswordPage} />
                            <Route exact path={ `${process.env.PUBLIC_URL}/usuario/verify-user`} component={VerifyUserPage} />

                            <Route path='*' component={ ErrorPage404 } />
                        </Switch>
                    </Layout>
                </Switch>
        </React.Suspense>
    )
}



export default AppRoot;


