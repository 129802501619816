import React, { useState } from 'react';
import DescripcionFila from './descripcion-fila'
import ReactTable from "../../common/react-table";
import './index.css'


function ArticuloList(props) {

  const {
          usuario,
          rows,
          cantidad,
          setCantidad,
          onClick_IconCart,
          viewRowFilters,
          columnsArticuloList,
          showArticuloInfoList,
          setShowArticuloInfoList,
          snVisibleDescripcionFila,
          pageCount,
          loadData,
          pageIndex, 
          setPageIndex
        } = props


//descripcion-articulo
  const [selectedRowArticulo,setSelectedRowArticulo] = useState('');
  const [imagenCargada, setImagenCargada] = useState(false);
//descripcion-articulo

  


function handleChange_CantidadArticulos(newValue) { //la funcion "handleChange_CantidadArticulos" es llamada por el Children <DescripcionFila/>
  const value = newValue;
  const setValue = value;
  setCantidad(setValue);
}


//======================================================== ON SELECT ROW ================================================
  function onSelectRowReactTable(row) {
    setShowArticuloInfoList(!!row);
    setSelectedRowArticulo(row);
    setCantidad(1);
    setImagenCargada(false);
  }

//====================================================  RENDER  =========================================================    
  return (
    <div>
      <ReactTable
        columnsTable={columnsArticuloList}
        data={rows ? rows.datos : []}
        dataAux={rows}
        onSelectRowReactTable = {onSelectRowReactTable}
        viewRowFilters = {viewRowFilters}
        pageCount={pageCount}
        loadData = {loadData}
        pageIndex = {pageIndex}
        setPageIndex = {setPageIndex}
      />

      <br/>

      {
        
          snVisibleDescripcionFila && showArticuloInfoList &&
              <DescripcionFila 
                  selectedRowArticulo={selectedRowArticulo} //le paso al Children <DescripcionFila/> la funcion selectedRowArticulo
                  cantidad = {cantidad} //le paso al Children <DescripcionFila/> la constante cantidad
                  onClick={onClick_IconCart} //le paso al Children <DescripcionFila/> la funcion onClick_IconCart
                  onChange={handleChange_CantidadArticulos}  //le paso al Children <DescripcionFila/> la funcion handleChange_CantidadArticulos
                  usuario={usuario}
                  imagenCargada = {imagenCargada}
                  setImagenCargada = {setImagenCargada}
              />
      }

    </div>
  );

}

  
export default ArticuloList