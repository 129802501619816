import React from 'react';
import Pedidos from '../../pedidos';
import { Helmet } from 'react-helmet';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';

function PedidosAllPage() {
  return (

    <div>
        <Helmet>
            <title>Encendido Alsina - Usuarios Pedidos</title>
        </Helmet>
        
        <h1 className="d-none">Encendido Alsina - Usuarios Pedidos</h1>

        <div className="main">
            <Breadcrumb  parent1={ [ "Usuarios Pedidos", "get-pedidos-all" ] } adClass="mb-2" />

            <div className="page-content">
                <div className="container">
                    <div>
                      <Pedidos
                        allPedidos = {true}
                      />
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default PedidosAllPage;


