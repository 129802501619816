
import { usuarioPedidoConstantes } from './types';
import { usuarioPedidosService , alertService } from '@services';


export const usuarioPedidoActions = {
    finalizarPedido,
    getPedidoByIdUsuarioNotFinalized,
    agregarArticuloPedido,
    eliminarArticuloPedido,
    modificarArticuloPedido,
    getPedidosAll,
    getPedidosByIdUsuario,
    modificarSnYaLoPrepare,
    getPedidoDetallesByIdUsuarioPedido
};


function agregarArticuloPedido( articulo, cantidad , idUsuario , idEmpresa , utilidad) {
    const payload = {
        articulo,
        cantidad,
        idUsuario,
        idEmpresa,
        utilidad
    };

   
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );
        
        return new Promise((resolve, reject) => {
            usuarioPedidosService.agregarArticuloPedido(payload)
                    .then(
                        articuloAgregado => { 
                            dispatch( success(articuloAgregado));
                            alertService.success('Articulo agregado al carrito');
                            resolve(articuloAgregado); // respuesta correcta
                        },
                        error => {
                        
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                        .finally(() => {
                            document.querySelector( 'body' ).classList.add( "loaded" );
                        });
                        
            })
        };

        function success(articuloAgregado) { return { type: usuarioPedidoConstantes.AGREGAR_ARTICULO_PEDIDO_SUCCESS,  articuloAgregado } }
}


function eliminarArticuloPedido(idUsuario, idArticulo) {

    const payload = {
        idUsuario,
        idArticulo
    };


    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            usuarioPedidosService.eliminarArticuloPedido(payload)
                    .then(
                        articuloEliminado => { 
                            dispatch( success(articuloEliminado));
                            alertService.error('Articulo eliminado del carrito');
                            resolve(articuloEliminado); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                        .finally(() => {
                            document.querySelector( 'body' ).classList.add( "loaded" );
                        });
            })
        };

        function success(articuloEliminado) { return { type: usuarioPedidoConstantes.ELIMINAR_ARTICULO_PEDIDO_SUCCESS,  articuloEliminado } }
}


function modificarArticuloPedido( idUsuario,idArticulo, cantidad) {
    const payload = {
        idUsuario,
        idArticulo,
        cantidad
    };

    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            usuarioPedidosService.modificarArticuloPedido(payload)
                    .then(
                        articuloModificado => { 
                            dispatch( success(articuloModificado));
                            resolve(articuloModificado); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                        .finally(() => {
                            document.querySelector( 'body' ).classList.add( "loaded" );
                        });
            })
        };
    
        function success(articuloModificado) { return { type: usuarioPedidoConstantes.MODIFICAR_ARTICULO_PEDIDO_SUCCESS,  articuloModificado } }
}


function finalizarPedido(pedido) {
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            usuarioPedidosService.finalizarPedido(pedido)
                    .then(
                        _pedido => { 
                            dispatch(success());
                            
                            if(_pedido.usuarioPedido.snEnvioMail) {
                                alertService.success(_pedido.usuarioPedido.respuestaEnvioMail);
                            }
                            else {
                                alertService.warn(_pedido.usuarioPedido.respuestaEnvioMail);
                            }

                            resolve(_pedido); // respuesta correcta
                        },
                        error => {
                         
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                    .finally(() => {
                        document.querySelector( 'body' ).classList.add( "loaded" );
                    });
              
                })
            };
        

    function success() { return { type: usuarioPedidoConstantes.FINALIZAR_PEDIDO_SUCCESS } }
}


function getPedidoByIdUsuarioNotFinalized( idUsuario ) {
    return dispatch => {


        return new Promise((resolve, reject) => {
            usuarioPedidosService.getPedidoByIdUsuarioNotFinalized(idUsuario)
                    .then(
                        usuarioPedido => { 

                            const payload = {
                                usuarioPedido,
                                idUsuario
                            };

                            dispatch( success(payload));
                            resolve(payload); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                       
            })
        };

        function success(payload) { return { type: usuarioPedidoConstantes.GET_PEDIDO_BY_ID_USUARIO_NOT_FINALIZED_SUCCESS,  payload } }
}

function getPedidosAll(payload) {
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            usuarioPedidosService.getPedidosAll(payload)
                    .then(
                        usuarioPedidos_All => {
                            
                            dispatch( success(usuarioPedidos_All));
                            
                            resolve(usuarioPedidos_All); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                        .finally(() => {
                            document.querySelector( 'body' ).classList.add( "loaded" );
                        });
            })
        };

        function success(usuarioPedidos_All) { return { type: usuarioPedidoConstantes.GET_PEDIDOS_ALL_SUCCESS,  usuarioPedidos_All } }
}


function getPedidosByIdUsuario(payload) {
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            usuarioPedidosService.getPedidosByIdUsuario(payload)
                    .then(
                        usuarioPedidos => {
                            
                            dispatch( success(usuarioPedidos));
                            
                            resolve(usuarioPedidos); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                    //document.querySelector( 'body' ).classList.add( "loaded" ); -> se agrega en useEffect en ReactTable
            })
        };

        function success(usuarioPedido) { return { type: usuarioPedidoConstantes.GET_PEDIDOS_BY_ID_USUARIO_SUCCESS,  usuarioPedido } }
}

function modificarSnYaLoPrepare(payload) {
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            usuarioPedidosService.modificarSnYaLoPrepare(payload)
                    .then(
                        usuarioPedidos => {
                            dispatch( success(usuarioPedidos));
                            
                            resolve(usuarioPedidos); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                    //document.querySelector( 'body' ).classList.add( "loaded" ); -> se agrega en useEffect en ReactTable
            })
        };

        function success(usuarioPedido) { return { type: usuarioPedidoConstantes.MODIFICAR_SNYALOPREPARE,  usuarioPedido } }
}



function getPedidoDetallesByIdUsuarioPedido( payload ) {
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );
        
        return new Promise((resolve, reject) => {
            usuarioPedidosService.getPedidoDetallesByIdUsuarioPedido(payload)
                    .then(
                        usuarioPedidoDetalles => { 
                            dispatch( success(usuarioPedidoDetalles));
                            resolve(usuarioPedidoDetalles); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                    //document.querySelector( 'body' ).classList.add( "loaded" ); -> se agrega en useEffect en ReactTable
            })
        };

        function success(usuarioPedidoDetalles) { return { type: usuarioPedidoConstantes.GET_PEDIDO_DETALLES_BY_ID_USUARIO_PEDIDO_SUCCESS,  usuarioPedidoDetalles } }
}