import React, { useState, useEffect } from 'react';

import DownloadCatalogosListPage from "./list";
import AltaEditarPage from "./alta_editar";

import ButtonCustom from '@buttonCustom';
import { usuarioService } from '@services'
import {rol} from '@helpers'


import './index.css'

import { Helmet } from 'react-helmet';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';


export default function DownloadCatalogosPage(props) {
  
  const {vengoDesdeMiCuenta} = props;

  const usuario = usuarioService.usuarioValue;
  const [rowSelected , setRowSelected] = useState([]);
  const [snAlta,setSnAlta] = useState(0);
  const [snLocal,setSnLocal] = useState(1);
  const [snVisibleSubirCatalogo,setSnVisibleSubirCatalogo] = useState(false);

  useEffect( () => {

    //EL FILE LO VOY A SUBIR A MI PC LOCAL (1) O LO SUBO EN EL SERVIDOR (0)
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      setSnLocal(1)
    }
    else {
      setSnLocal(0)
    }   
  }, []);

  const handleClick = () => {
    setRowSelected(undefined);
    setSnAlta(-1);
    setSnVisibleSubirCatalogo(true)
  };

  const ReturnMain = () => {
    return (
        snVisibleSubirCatalogo 
                                ? <AltaEditarPage 
                                      rowSelected = {rowSelected}
                                      snAlta=  {snAlta}
                                      snLocal= {snLocal}
                                      setSnVisibleSubirCatalogo = {setSnVisibleSubirCatalogo}
                                  />
                                : <div>
                                      {
                                        usuario.rol === rol.Admin &&
                                        <div className="button_subir_catalogo">
                                          <ButtonCustom 
                                            type="button"
                                            isSubmitting={false} 
                                            text="Subir Catálogo"
                                            onClick={handleClick}
                                          />   
                                        </div>
                                      }
                                      <hr/>

                                      <DownloadCatalogosListPage
                                          setRowSelected = {setRowSelected}
                                          setSnAlta=  {setSnAlta}
                                          snLocal= {snLocal}
                                          setSnVisibleSubirCatalogo = {setSnVisibleSubirCatalogo}
                                      />   
                                  </div>
                                
    )
  }
  

  return (
    vengoDesdeMiCuenta ? <ReturnMain/>
                       :
                          <div>
                            <Helmet>
                                <title>Encendido Alsina - Admin Catálogos</title>
                            </Helmet>
                            
                            <h1 className="d-none">Encendido Alsina - Admin Catálogos</h1>

                            <div className="main">
                              <Breadcrumb  parent1={ [ "Admin Catálogos", "download_catalogos" ] } adClass="mb-2" />

                              <div className="page-content">
                                  <div className="container">
                                    <ReturnMain/>
                                  </div>
                              </div>
                            </div>
                          </div>
  );
}