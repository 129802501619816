import React,{useEffect} from 'react';
import Breadcrumb from '@breadcrumb';
import FormikRegisterUpdate from './formikRegisterUpdate'

function Register_UpdateUserPage(props) {

    useEffect( () => {  
        document.querySelector( 'body' ).classList.add( "loaded" ); //esto lo agrego ya que no llamo en este componente a reactTable , y si no pongo esto queda corriendo los puntitos rojos indefinidamente     
    } )

    let deDondeVengo = props.location ? parseInt(props.location.state.deDondeVengo) : parseInt(props.deDondeVengo);
   
  
    return( 
        <>
            {
                deDondeVengo === 1 &&
                <div>
        
                    <div className="main">
                        <div>
                            <Breadcrumb title="Registrarse" parent1={ [ "Iniciar Sesión", "usuario/login" ] } />
                        </div>

                    
                        <div className="page-content">
                                <div className="container">
                                    <FormikRegisterUpdate
                                        deDondeVengo = {deDondeVengo}
                                    />
                                </div>
                        </div>
                    </div>
                </div>
            }
            {
                deDondeVengo === 2 &&
                <FormikRegisterUpdate
                    deDondeVengo = {deDondeVengo}
                />
            }
        </>
    )
} 

export default Register_UpdateUserPage;
