import {  dropDownCartMenuConstantes } from '@actions/types';


const initialState = {
    isOpenDropdownCartMenu: false
}

export default function dropDownCartMenuReducer( state = initialState, action ) {
    switch ( action.type ) {
        case dropDownCartMenuConstantes.OPEN_DROPDOWN:
                return {
                    isOpenDropdownCartMenu : true
                };
        case dropDownCartMenuConstantes.CLOSE_DROPDOWN:
                return {
                    isOpenDropdownCartMenu : false
                };
        default:
            return state;
    }
}
