// import Avatar from "react-avatar";
import React, { useState} from 'react';
import { Link } from "react-router-dom";

import { connect } from 'react-redux';


import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';

import { usuarioService } from '@services'
import {rol} from '@helpers'
import { downloadCatalogoActions } from '@actions';

import ReactTable from "../../../common/react-table";

//para cargar archivos de 5mb tuve que agregar en webpackDevServer.config (ignored: [ ignoredFiles(paths.appSrc), paths.appPublic ])
export function DownloadCatalogosListPage(props) {
  const { 
          getAllActive,
          eliminarDownloadCatalogo,
          
          setRowSelected,
          setSnAlta,
          snLocal,
          setSnVisibleSubirCatalogo
        } = props;

  const usuario = usuarioService.usuarioValue;      
  const [rows, setRows] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const rowsPerPage = 10;

  //la primera carga del componente se hace desde ReactTable

  async function loadData(pageIndex) {
    await getAllActive(pageIndex,rowsPerPage)
    .then(newRows => {
      setRows(newRows);
      setPageCount(Math.ceil(newRows.total / rowsPerPage))
    })
    .catch(() => {
      setRows(null);
      setPageCount(0)
    })
  }

  function deleteCatalogo(cell) {

    const payload = 
    {
        idDownloadCatalogo: parseInt(cell.row.original.id),
        snLocal: snLocal,
        nombreArchivo: cell.row.original.nombreArchivo
    }

    eliminarDownloadCatalogo(payload)
    .then(() => {
      loadData();
    
    })
  }

  const columns = [

    {
      Header: '#',
      accessor: 'id',
      show: true
    },
    {
      Header: 'Nombre Archivo',
      accessor: 'nombreArchivo',
      show: true
    },
    {
      Header: 'Editar',
      accessor: 'editar',
      show: usuario.rol === rol.Admin ? true : false,
      Cell: ({ cell }) => {

        const onClick = () => {
          setRowSelected(cell.row.original);
          setSnAlta(0);

          setSnVisibleSubirCatalogo(true)
        };
  
        return <Button variant="outlined" startIcon={<EditIcon />} onClick={onClick} color='success'>
                  Editar
                </Button>
      }
    },
    {
      Header: 'Eliminar',
      accessor: 'eliminar',
      show: usuario.rol === rol.Admin ? true : false,
      Cell: ({ cell }) => {

        const onClick = () => {
          deleteCatalogo(cell);
        };
  
        return <Button variant="outlined" startIcon={<DeleteIcon />} onClick={onClick} color='error'>
                  Eliminar
                </Button>
      }
    },
    {
      Header: 'Descargar',
      accessor: 'descargar',
      show: true,
      Cell: ({ cell }) => {
        return  <Link to={cell.row.original.path} target="_blank" download>
                  <Button variant="outlined" startIcon={<DownloadIcon />} color='primary'>
                    Descargar
                  </Button>
              </Link>
      }
    }
  ];



  return (
    <div>

      <ReactTable
        columnsTable={columns}
        data={rows ? rows.datos : []}
        dataAux={rows}
        onSelectRowReactTable = {undefined}
        viewRowFilters = {false}
        pageCount={pageCount}
        loadData = {loadData}
        pageIndex = {pageIndex}
        setPageIndex = {setPageIndex}

      />
    </div>
  );
}

const actionCreators = {
  getAllActive: downloadCatalogoActions.getAllActive,
  eliminarDownloadCatalogo: downloadCatalogoActions.eliminarDownloadCatalogo,
};



export default connect(null, actionCreators)(DownloadCatalogosListPage);