
import React, { useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';


import {  usuarioPedidoActions } from '@actions';
import { connect } from 'react-redux';
import ReactTable from "../../common/react-table";



function PedidoDetalle(props) {

  const {
    idUsuarioPedidoSeleccionado,
    setOpen,
    open,
    getPedidoDetallesByIdUsuarioPedido
  } = props
  

  const [rows, setRows] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const rowsPerPage = 6;

  //la primera carga del componente se hace desde ReactTable


  const valueFormatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
  });

  const columns = [
    {
      Header: '#',
      accessor: 'id',
      show: true,
      width: 70
    },
    {
      Header: 'Cantidad',
      accessor: 'cantidad',
      show: true,
      width: 120
    },
    {
      Header: 'Codigo Articulo',
      accessor: 'codigoArticulo',
      show: true,
      width: 150
    },
   
    {
      Header: 'Descripcion Articulo',
      accessor: 'descripcionArticulo',
      show: true,
      width: 180
    },
    {
      Header: 'Marca',
      accessor: 'txtDescMarca',
      show: true,
      width: 120
    },
    {
      Header: 'Familia',
      accessor: 'txtDescFamilia',
      show: true,
      width: 120
    },
    {
      Header: 'Precio',
      accessor: 'precioListaPorCoeficientePorMedioIva',
      show: true,
      Cell: props => valueFormatter.format(props.value),
      show: true,
      width: 120
    }
  ];

  
  const handleClose_Dialog = () => {
    setOpen(false)
  };


  async function loadData (pageIndex) {

    const payload = {
      idUsuarioPedido: idUsuarioPedidoSeleccionado,
      skip: pageIndex,
      take: rowsPerPage
    }

    let active = true;
      (async () => {
        await getPedidoDetallesByIdUsuarioPedido(payload)
        .then(newRows => {
          if (!active) {
            return;
          }
    
          setRows(newRows);
          setPageCount(Math.ceil(newRows.total / rowsPerPage))
        })
        .catch(() => {
          setRows(null);
          setPageCount(0)
        })
      })();

      return () => {
        active = false;
      };
  }


  return (
    <div>
      <Dialog fullWidth={ true } maxWidth={"md"} onClose={handleClose_Dialog} aria-labelledby="customized-dialog-title" open={open} >
        <DialogTitle id="customized-dialog-title" onClose={handleClose_Dialog}>
          <div>
            <h3>Pedido Detalle</h3>
          </div>
        </DialogTitle>
        <DialogContent dividers>
            <ReactTable
              columnsTable={columns}
              data={rows ? rows.datos : []}
              dataAux={rows}
              onSelectRowReactTable = {undefined}
              viewRowFilters = {false}
              pageCount={pageCount}
              loadData = {loadData}
              pageIndex = {pageIndex}
              setPageIndex = {setPageIndex}
            />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const actionCreators = {
  getPedidoDetallesByIdUsuarioPedido: usuarioPedidoActions.getPedidoDetallesByIdUsuarioPedido
}

export default connect(null, actionCreators)(PedidoDetalle);

