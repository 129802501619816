import {   sincronizarTablaConstantes } from '@actions/types';


const initialState = {
  bandera: false,         //esta variable indica si el proceso de sincronizarTabla fue correcto o no
  actionEnProceso: false, //esta variable indica si el proceso de sincronizarTabla se esta ejecutando
}


export default function sincronizarTablaReducer(state = initialState, action) {
  switch (action.type) {
    case sincronizarTablaConstantes.SINCRONIZAR_TABLA_REQUEST:
      return {
        bandera: false,
        actionEnProceso: true
      };
    case sincronizarTablaConstantes.SINCRONIZAR_TABLA_SUCCESS:
      return {
        bandera: action.bandera,
        actionEnProceso: false
      };
    case sincronizarTablaConstantes.SINCRONIZAR_TABLA_FAILURE:
      return {
        bandera: false,
        actionEnProceso: false
      };
    default:
      return state
  }
}