import React,{useMemo} from 'react';
import { Helmet } from 'react-helmet';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';
import Articulo from '../../articulo';
import { usuarioService } from '@services'

function CatalogoPage( props ) {
    let grid = props.match.params.grid; //esta prop viene de components\home\index.jsx
    if ( grid !== "list") {
        window.location = process.env.PUBLIC_URL + "/error-404";
    }

    const usuario = usuarioService.usuarioValue;

//======================================================== COLUMNS ARTICULO LIST ================================================
    const valueFormatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    });

    const columnsArticuloList = useMemo(
        () => [
        {
            Header: 'id',
            accessor: 'id',
            show: false
        },
        {
            Header: 'Marca',
            accessor: 'marcaArticulo',
            show: true,
            width: 110
        },
        {
            Header: 'Codigo',
            accessor: 'codigoArticulo',
            show: true,
            width: 117
        },
        {
            Header: 'Descripcion',
            accessor: 'descripcionArticulo',
            show: true
        },
        {
            Header: 'Familia',
            accessor: 'familiaArticulo',
            show: true,
            width: 170
        },
        {
            Header: 'Precio Costo',
            accessor: 'precioListaPorCoeficientePorMedioIva',
            Cell: props => valueFormatter.format(props.value),
            show: usuario ? true : false,
            width: 120
        },
        {
            Header: 'Precio Utilidad',
            accessor: 'utilidadArticulo',
            Cell: props => valueFormatter.format(props.value),
            show: usuario ? true : false,
            width: 120
        },
        {
            Header: 'snOferta',
            accessor: 'snOferta',
            show: false
        },
        {
            Header: 'pathImagenArticulo',
            accessor: 'pathImagenArticulo',
            show: false
        },
        {
            Header: 'precioLista',
            accessor: 'precioLista',
            show: false
        },
        {
            Header: 'coeficiente',
            accessor: 'coeficiente',
            show: false
        },
        {
            Header: 'idTablaMarca',
            accessor: 'idTablaMarca',
            show: false
        },
        {
            Header: 'idTablaFamilia',
            accessor: 'idTablaFamilia',
            show: false
        },
        ],
        []
    )


    return (
        <div>
            <Helmet>
                <title>Encendido Alsina - Catalogo</title>
            </Helmet>
            
            <h1 className="d-none">Encendido Alsina - Catalogo</h1>

            <div className="main">
                <Breadcrumb  parent1={ [ "Catalogo", "catalogo/list" ] } adClass="mb-2" />

                <div className="page-content">
                    <div className="container">
                        <div>
                            <div>
                                <Articulo 
                                    column={ grid } 
                                    vengoDeImagenesArticulos = {false}
                                    snVisibleIdArticulo = {false}
                                    snVisibleUtilidad = {true}
                                    snVisibleSwitchOferta = {true}
                                    viewRowFilters = {false}
                                    columnsArticuloList = {columnsArticuloList}
                                    snVisibleDescripcionFila = {true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo( CatalogoPage );