
import { usuarioCuentaCorrienteConstantes } from './types';
import { usuarioCuentaCorrienteService , alertService } from '@services';

export const usuarioCuentaCorrienteActions = {
     getAllActive
};


function getAllActive(payload) {
    return dispatch => {

        document.querySelector( 'body' ).classList.remove( "loaded" );

        return new Promise((resolve, reject) => {
            usuarioCuentaCorrienteService.getAllActive(payload)
                    .then(
                        usuarioCuentaCorriente => {
                            
                            dispatch( success(usuarioCuentaCorriente));
                            
                            resolve(usuarioCuentaCorriente); // respuesta correcta
                        },
                        error => {
                            alertService.error(error);
                            reject(error); // respuesta error
                        })
                    .finally(() => {
                        document.querySelector( 'body' ).classList.add( "loaded" );
                    });
            })
        };

        function success(usuarioCuentaCorriente) { return { type: usuarioCuentaCorrienteConstantes.GET_ALL_ACTIVE_SUCCESS_USUARIOCUENTACORRIENTE,  usuarioCuentaCorriente } }
}

