import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { usuarioService } from '@services'
import {rol} from '@helpers'

// El componente PrivateRouteAdmin representa un componente de ruta si el usuario ha iniciado sesión  y tiene un rol autorizado para la ruta,
// si el usuario no ha iniciado sesión, se le redirige a la /login página, 
// si el usuario ha iniciado sesión pero no rol autorizado son redirigidos a la página de inicio.




function PrivateRouteAdmin({ component: Component, ...rest }) {
    return (
        <Route {...rest} render={props => {
            const usuario = usuarioService.usuarioValue;
            
            if (!usuario) { // si el usuario no esta logueado....
                return <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/usuario/login`, state: { from: props.location } }} />
            }
            else { //si el usuario esta logueado pero el rol es diferente a Admin...
                if(usuario.rol !== rol.Admin) {
                    return <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/`, state: { from: props.location } }} />
                }
            }

    
            
            // authorized so return component
            return <Component {...props} />
        }} />
    );
}

export { PrivateRouteAdmin};