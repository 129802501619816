import React, {useState,useEffect } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { ErrorMessage} from "formik";
import './index.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { connect } from 'react-redux';

function LocationSearchInput( props ) {
  const { 
    field: { name }, // { name, value, onChange, onBlur }
    form: { touched, errors , setFieldValue , setFieldError , setFieldTouched }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    value,
    usuario,
  } = props;

  const error = errors[name];
  const touch = touched[name];
  const [address,setAddress] = useState(value || "");

  useEffect(() => {
      if (usuario) {
      setAddress(usuario.direccionValor)
      
      setFieldValue(name, {
        value: usuario.direccionValor,
        address: usuario.direccionValor,
        coordinates: {
          lat: parseFloat(usuario.lat),
          lng: parseFloat(usuario.lng)
        }
      })}
  }, [])




  function handleError(error)  {
    setFieldError(name, error);
  };

  function handleChange (address)  {
      setFieldTouched(`${name}.value`);
      setFieldTouched(`${name}.address`);
      setFieldValue(name, { value: address });

      setAddress(address)
  };

  function handleSelect (address) {
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
          setFieldValue(name, {
            value: address,
            address,
            coordinates: latLng
          });
          setAddress(address)
      })
      .catch(error => setFieldError(name, error));
  };

  //cuando saco el foco del input
  function onBlur  ()  {
    if(address === '') {
        setFieldTouched(`${name}.value`);
        setFieldTouched(`${name}.address`);
        setFieldValue(name, { value: '' });
    }
  }

  return (
    <PlacesAutocomplete
      name={name}
      id={name}
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={handleError}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading  }) => (

        <div>
          
          <input
            { 
              ...getInputProps({
              placeholder: "Buscar dirección ...",
              className: "location-search-input form-control form-controlCustom" + (error && touch ? ' is-invalid' : '')
            })}
            onBlur={onBlur} //cuando saco el foco del input
            
          />
          
          <div className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion,index) => {
              
              
              // inline style for demonstration purpose
              const style = suggestion.active
              ? { backgroundColor: '#42a5f5', cursor: 'pointer' }
              : { backgroundColor: '#ffffff', cursor: 'pointer'};
              return (
                <div className="locationSearchInput-inputSuggestion" 
                  {...getSuggestionItemProps(suggestion, {
                    style,
                  })}
                  key = {index}
                >
                    
                    <LocationOnIcon/> <span>{suggestion.description}</span>
            
              </div>
              );
            })}
          </div>

          {error && error.value && <ErrorMessage name='location.value'  component="div" className="invalid-feedback" />}
          {error && !error.value && error.address && <ErrorMessage name='location.address'  component="div" className="invalid-feedback" />}
        </div>
      )}
    </PlacesAutocomplete>
  );
  
}


export default connect(null, null)(LocationSearchInput);
