import React from 'react';
import { Link } from 'react-router-dom';

export default function AdminMenu() {
   
    return (
        <li id="menu-home">
            <Link  to="#"  className="sf-with">Admin</Link>
            <ul>
                <li><Link to={ `${process.env.PUBLIC_URL}/get_pedidos_all` }>PEDIDOS USUARIOS</Link></li>
                <li><Link to={ `${process.env.PUBLIC_URL}/sincronizar_tabla` }>SINCRONIZAR TABLAS</Link></li>
                <li><Link to={ `${process.env.PUBLIC_URL}/imagenes_articulos/list` }>ADMIN IMAGENES ARTICULOS</Link></li>
                <li><Link to={ `${process.env.PUBLIC_URL}/download_catalogos` }>ADMIN CATALOGOS</Link></li>
                <li><Link to={ `${process.env.PUBLIC_URL}/admin_users_inactives` }>ADMIN USUARIOS INACTIVOS</Link></li>
            </ul>
        </li>
    );
}
