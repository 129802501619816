import React, { useEffect,useState } from "react";
import { sincronizarTablaActions } from '@actions';
import { connect } from 'react-redux';
import ButtonCustom from '@buttonCustom';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../../common/breadcrumb';
import {  HubConnectionBuilder } from "@microsoft/signalr";


export  function SincronizarTablaPage(props) {
  const { 
        sincronizarTabla
  } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseSincronizarTabla, setResponseSincronizarTabla] = useState('');
  const [isOkResponseSincronizarTabla,setIsOkResponseSincronizarTabla] = useState(false);

  const [connectionHub, setConnectionHub] = useState(null);
  const [taskMessageHub, setTaskMessageHub] = useState([]);
  const [taskCompleteHub, setTaskCompleteHub] = useState("");

  useEffect(() => {
    document.querySelector( 'body' ).classList.add( "loaded" ); //esto lo agrego ya que no llamo en este componente a reactTable , y si no pongo esto queda corriendo los puntitos rojos indefinidamente
    const connect = new HubConnectionBuilder()
      .withUrl(`${process.env.PUBLIC_URL}/progress`)
      .withAutomaticReconnect()
      .build();

    setConnectionHub(connect);
  }, []);

  useEffect(() => {
    if (connectionHub) {
      connectionHub
        .start()
        .then(() => {
            connectionHub.on('taskStarted', () => {
                // console.log('task started');
            });
            connectionHub.on('taskMessage', data => {
                if(data.startsWith("Inicio")) {
                    const obj = { 
                        text: data,
                        color: 'red',
                        fontWeight: 'bold'
                    };

                    setTaskMessageHub(oldArray => [...oldArray, obj]);
                }
                else if(data.startsWith("Fin")) {
                    const obj = { 
                        text: data,
                        color: 'green',
                        fontWeight: 'normal'
                    };

                    setTaskMessageHub(oldArray => [...oldArray.map(obj => { return {...obj, color: 'green', fontWeight: 'normal'}}), obj]);
                }
            });
            connectionHub.on('taskComplete', data => {
                setTaskCompleteHub(data)
            });
            connectionHub.on('taskEnded', () => {
                // console.log('task ended');
            });    
        })
        .catch((error) => console.log(error));
    }
  }, [connectionHub]);


  function onClickSincronizarTabla() {
    setTaskCompleteHub("");
    setTaskMessageHub([]);
    setIsOkResponseSincronizarTabla(false);
    setResponseSincronizarTabla('');
    setIsSubmitting(true);

    sincronizarTabla()
    .then(bandera => {

        if(bandera) {
            setIsOkResponseSincronizarTabla(true)
            setResponseSincronizarTabla('Sincronización Exitosa')
        }
        else {
            setIsOkResponseSincronizarTabla(false)
            setResponseSincronizarTabla('Sincronización Erronea')
        }
        setIsSubmitting(false);
    })
    .catch((error) => {
        setIsOkResponseSincronizarTabla(false)
        setResponseSincronizarTabla(error)
        setIsSubmitting(false);
      })
  }

  
  return (
        <div>
            <Helmet>
                <title>Encendido Alsina - Sincronización de Tablas</title>
            </Helmet>
            
            <div className="main">
                <div>
                    <Breadcrumb parent1={ [ "Sincronización de Tablas", "sincronizar_tabla" ] } adClass="mb-2" />
                </div>

                <div className="page-content">
                    <div className="container">
                        <p className="text-danger font-weight-bold">* En caso de que el proceso se cuelgue , recargar la página</p>
                        <p className="text-danger font-weight-bold">* Se van a sincronizar las siguientes tablas de PC LOCAL con la WEB</p>
                        <p className="text-danger">articulo</p>
                        <p className="text-danger">familia</p>
                        <p className="text-danger">marca</p>
                        <p className="text-danger">usuario_cuenta_corriente</p>

                        <br/>

                        <ButtonCustom 
                            type="button"
                            isSubmitting={isSubmitting} 
                            text="Sincronizar"
                            onClick={onClickSincronizarTabla}
                        />   

                        <br/>
                        <br/>

                        <div style={{fontWeight: "bold"}}>
                            { taskCompleteHub }
                        </div>

                        <br/>

                        <div>
                            {taskMessageHub.map( (tm,key) =>
                                <div 
                                    key={key} 
                                    style={{color: tm.color , fontWeight: tm.fontWeight}}
                                >
                                    { tm.text }
                                </div>
                            )}
                        </div>

                        <br/>
                      
                        {
                            responseSincronizarTabla && (
                                isOkResponseSincronizarTabla ? <div className="p-3 mb-2 bg-success text-white">{responseSincronizarTabla}</div>
                                                             : <div className="p-3 mb-2 bg-danger text-white">{responseSincronizarTabla}</div>
                            )
                        }
                    </div>
                </div>

               
       
            </div>
        </div>
  );
}



const actionCreators = {
    sincronizarTabla: sincronizarTablaActions.sincronizarTabla
};
  
  
  
export default connect(null, actionCreators)(SincronizarTablaPage);
