// import Avatar from "react-avatar";
import React, { useState} from 'react';

import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DeleteIcon from '@mui/icons-material/Delete';

import { usuarioActions } from '@actions';

import ReactTable from "../../../common/react-table";

export function AdminUsersInactivesListPage(props) {
  const { 
          getUsersInactives,
          verifyUser,
          deleteInactiveUser
        } = props;
  
  const [rows, setRows] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const rowsPerPage = 10;

  //la primera carga del componente se hace desde ReactTable
  async function loadData(pageIndex) {
    await getUsersInactives(pageIndex,rowsPerPage)
    .then(newRows => {

      if(newRows.total === 0) {
        document.querySelector( 'body' ).classList.add( "loaded" );
      }

      setRows(newRows);
      setPageCount(Math.ceil(newRows.total / rowsPerPage))
    })
    .catch(() => {
      setRows(null);
      setPageCount(0)
    })
  }

  const columns = [
    {
      Header: '#',
      accessor: 'idUsuario',
      show: true
    },
    {
      Header: 'Razon Social',
      accessor: 'razonSocial',
      show: true
    },
    {
      Header: 'Nombre',
      accessor: 'nombre',
      show: true
    },
    {
      Header: 'Apellido',
      accessor: 'apellido',
      show: true
    },
    {
      Header: 'Activar Usuario',
      accessor: 'activar',
      show: true,
      Cell: ({ cell }) => {

        const onClickActiveUser = () => {
          verifyUser(cell.row.original.tokenVerificacion,'Verificación exitosa , el usuario ' + cell.row.original.razonSocial + ' puede iniciar sesión y fue notificado via email',false)
          .finally(() => {
            loadData(0);
          })
        };
  
        return <Button variant="outlined" startIcon={<NotificationsActiveIcon />} onClick={onClickActiveUser} color='success'>
                  Activar
                </Button>
      }
    },
    {
      Header: 'Eliminar Usuario Inactivo',
      accessor: 'eliminar',
      show: true,
      Cell: ({ cell }) => {

        const onClickDeleteInactiveUser = () => {
          deleteInactiveUser(cell.row.original.idUsuario)
          .finally(() => {
            loadData(0);
          })
        };
  
        return <Button variant="outlined" startIcon={<DeleteIcon />} onClick={onClickDeleteInactiveUser} color='error'>
                  Eliminar
                </Button>
      }
    }
  ];



  return (
    <div>
      <ReactTable
        columnsTable={columns}
        data={rows ? rows.datos : []}
        dataAux={rows}
        onSelectRowReactTable = {undefined}
        viewRowFilters = {false}
        pageCount={pageCount}
        loadData = {loadData}
        pageIndex = {pageIndex}
        setPageIndex = {setPageIndex}
      />
    </div>
  );
}

const actionCreators = {
  getUsersInactives: usuarioActions.getUsersInactives,
  verifyUser: usuarioActions.verifyUser,
  deleteInactiveUser: usuarioActions.deleteInactiveUser
};



export default connect(null, actionCreators)(AdminUsersInactivesListPage);