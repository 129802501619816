
import { dropDownCartMenuConstantes } from './types';



export const dropDownCartMenuActions = {
   openDropDown,
   closeDropDown
};


function openDropDown() {
    return dispatch => {
        dispatch( success());
    };

    function success() { return { type: dropDownCartMenuConstantes.OPEN_DROPDOWN } }
}


function closeDropDown() {
    return dispatch => {
        dispatch( success());
    };

    function success() { return { type: dropDownCartMenuConstantes.CLOSE_DROPDOWN } }
}