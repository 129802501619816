// import Avatar from "react-avatar";
import React,{useMemo} from 'react';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { usuarioService } from '@services'
import {rol} from '@helpers'
import Articulo from '../../../articulo';

export function ImagenesArticulosListPage(props) {
  const {     
          grid, //esta prop viene de imagenes_articulos\index.jsx
          setRowSelected,
          setSnVisibleEditarImagenArticulo
        } = props;

  const usuario = usuarioService.usuarioValue;
  if ( grid !== "list") {
      window.location = process.env.PUBLIC_URL + "/error-404";
  }

  //======================================================== COLUMNS ================================================
  const columnsArticuloList = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        show: true
      },
      {
        Header: 'Marca',
        accessor: 'marcaArticulo',
        show: true,
        width: 110
      },
      {
        Header: 'Codigo',
        accessor: 'codigoArticulo',
        show: true,
        width: 117
      },
      {
        Header: 'Editar',
        accessor: 'editar',
        show: usuario.rol === rol.Admin ? true : false,
        Cell: ({ cell }) => {
          const onClick = () => {
            setRowSelected(cell.row.original);
            setSnVisibleEditarImagenArticulo(true)
          };
    
          return <Button variant="outlined" startIcon={<EditIcon />} onClick={onClick} color='success'>
                    Editar
                  </Button>
        }
      },
    ],
    []
  )




  return (
    <div>

      <Articulo 
        column={grid} 
        vengoDeImagenesArticulos = {true}
        snVisibleIdArticulo = {true}
        snVisibleUtilidad = {false}
        snVisibleSwitchOferta = {false}
        viewRowFilters = {true}
        columnsArticuloList = {columnsArticuloList}
        snVisibleDescripcionFila = {false}
      />
    </div>
  );
}



export default ImagenesArticulosListPage;