import React, { useState, useEffect } from 'react';
import { Link ,  useLocation , useHistory} from 'react-router-dom';
import queryString from 'query-string';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet';
import Breadcrumb from '@breadcrumb';

import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { makeStyles } from "@mui/styles";
import '../index.css'
import { usuarioActions } from '@actions';
import { connect } from 'react-redux';
import ButtonCustom from '@buttonCustom';

function ResetPasswordPage(props) {
    let history = useHistory();
    let location = useLocation();
    const { resetPassword ,  validateResetToken } = props;

    const TokenStatus = {
        Validating: 'Validating',
        Valid: 'Valid',
        Invalid: 'Invalid'
    }
    
    const [token, setToken] = useState(null);
    const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);


    
    
    const useStyles = makeStyles(() => ({
        customHoverFocus: {
            "&:hover, &.Mui-focusVisible": { backgroundColor: "transparent" }
        }
    }));

    

    const classes = useStyles();

    useEffect(() => {
        document.querySelector( 'body' ).classList.add( "loaded" ); //esto lo agrego ya que no llamo en este componente a reactTable , y si no pongo esto queda corriendo los puntitos rojos indefinidamente   
        
        const {token } = queryString.parse(location.search);

        // remove token from url to prevent http referer leakage
        history.replace(location.pathname);

        validateResetToken(token)
            .then(() => {
                setToken(token);
                setTokenStatus(TokenStatus.Valid);
            })
            .catch(() => {
                setTokenStatus(TokenStatus.Invalid);
            });
    }, []);

    function getForm() {
        const initialValues = {
            password: '',
            confirmacionPassword: '',
            showPassword_password: false,
            showPassword_confirmacionPassword : false
        };

        const validationSchema = Yup.object().shape({
            password: Yup.string()
                // .matches(
                //     "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$",
                //     "Mínimo ocho caracteres, al menos una letra y un número"
                // )
                .min(8, 'La contraseña debe tener al menos 8 caracteres')
                .required('Contraseña es requerida')
                .max(15, 'Maximo 15 caracteres'),
            confirmacionPassword: Yup.string()
                .when('password', (password, schema) => {
                    if (password) return schema.required('Confirmación de contraseña es requerida');
                })
                .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
                // .matches(
                //     "^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$",
                //     "Mínimo ocho caracteres, al menos una letra y un número"
                // )
                .min(8, 'La contraseña debe tener al menos 8 caracteres')
                .required('Contraseña es requerida')
                .max(15, 'Maximo 15 caracteres'),
        });

        function onSubmit({ password, confirmacionPassword }, { setSubmitting }) {

            const payload = {
                token,
                password,
                confirmacionPassword
            };

            resetPassword(payload)
            .catch(() => 
                setSubmitting(false)
            )
        }


       

        return (

            <div>
                <Helmet>
                    <title>Encendido Alsina - Reestablecer contraseña</title>
                </Helmet>
                
                <h1 className="d-none">Encendido Alsina - Reestablecer contraseña</h1>
                
                <div className="main">
                    <Breadcrumb title="Reestablecer contraseña" parent1={ [ "Iniciar Sesión", "usuario/login" ] } />
                
                    <div className="page-content">
                            <div className="container">
                            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                                {({ errors, touched, isSubmitting ,setFieldValue,values }) => (
                                    <Form>
                                         <div className="form-group">
                                            <label>Contraseña</label>
                                            <IconButton 
                                                aria-label="toggle password visibility"
                                                onClick={ () => setFieldValue('showPassword_password', !values.showPassword_password)}
                                                edge="end"
                                                className={classes.customHoverFocus}
                                            >
                                                {values.showPassword_password ? 
                                                                            <label 
                                                                                    style={{margin:'20px' ,fontWeight: 'bold',color:'red'}}
                                                                            >Ocultar contraseña</label> 
                                                                        : 
                                                                            <label style={{margin:'20px' ,fontWeight: 'bold',color:'blue'}}
                                                                            >Mostrar contraseña</label>
                                                    }
                                                {values.showPassword_password ?   <Visibility  /> : <VisibilityOff />}
                                            </IconButton>
                                            <Field name="password"  type={values.showPassword_password ? 'text' : 'password'}  className={'form-control form-controlCustom' + (errors.password && touched.password ? ' is-invalid' : '')}/>
                                            <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-group">
                                            <label>Confirmar Contraseña</label>
                                            <IconButton 
                                                aria-label="toggle password visibility"
                                                onClick={ () => setFieldValue('showPassword_confirmacionPassword', !values.showPassword_confirmacionPassword)}
                                                edge="end"
                                                className={classes.customHoverFocus}
                                            >
                                                {values.showPassword_confirmacionPassword ? 
                                                                            <label 
                                                                                    style={{margin:'20px' ,fontWeight: 'bold',color:'red'}}
                                                                            >Ocultar contraseña</label> 
                                                                        : 
                                                                            <label style={{margin:'20px' ,fontWeight: 'bold',color:'blue'}}
                                                                            >Mostrar contraseña</label>
                                                    }
                                                {values.showPassword_confirmacionPassword ?   <Visibility  /> : <VisibilityOff />}
                                            </IconButton>
                                            <Field name="confirmacionPassword"  type={values.showPassword_confirmacionPassword ? 'text' : 'password'}  className={'form-control form-controlCustom' + (errors.confirmacionPassword && touched.confirmacionPassword ? ' is-invalid' : '')}/>
                                            <ErrorMessage name="confirmacionPassword" component="div" className="invalid-feedback" />
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <ButtonCustom 
                                                    type="submit" 
                                                    isSubmitting={isSubmitting} 
                                                    text="Restablecer contraseña"
                                                />           
                                                <Link to="/usuario/login" className="btn btn-link">Cancelar</Link>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }

    function getBody() {
        switch (tokenStatus) {
            case TokenStatus.Valid:
                return getForm();
            case TokenStatus.Invalid:
                return <div>La validación del token falló, si el token ha expirado, puede obtener uno nuevo en el <Link to="forgot-password">Olvide mi contraseña</Link></div>;
            case TokenStatus.Validating:
                return <div>Validando token ...</div>;
        }
    }

    return (
        <div>
            <div className="card-body">{getBody()}</div>
        </div>
    )
}



const actionCreators = {
    resetPassword : usuarioActions.resetPassword,
    validateResetToken : usuarioActions.validateResetToken
    
};



export default connect(null, actionCreators)(ResetPasswordPage);
