import React from "react";
import { downloadCatalogoActions } from '@actions';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import ButtonCustom from '@buttonCustom';


function AltaEditarPage(props) {
  const { 
    agregarDownloadCatalogo, 
    modificarDownloadCatalogo, 
    setSnVisibleSubirCatalogo,
    rowSelected, 
    snAlta, 
    snLocal
  } = props;


  const handleClickVolver = () => {
    setSnVisibleSubirCatalogo(false) //vuelvo a dashboard
  };


  function onSubmit({ fileCatalogo }, { setSubmitting }) {

    if(snAlta === -1) {
      var formData = new FormData();
      formData.append('file', fileCatalogo);
      formData.append('snLocal', snLocal); 


      agregarDownloadCatalogo(formData)
      .then(() => {
        setSubmitting(false)
        setSnVisibleSubirCatalogo(false) //vuelvo a dashboard
      })
      .catch(() => 
              setSubmitting(false)
      )
    }
    else if(snAlta === 0) {
      var formData = new FormData();
      formData.append('idDownloadCatalogo', rowSelected.id);
      formData.append('snLocal', snLocal); //EL FILE LO VOY A SUBIR A MI PC LOCAL (1) O LO SUBO EN EL SERVIDOR (0)
      formData.append('nameFileOld', rowSelected.nombreArchivo); 
      formData.append('fileNew', fileCatalogo);
  
      modificarDownloadCatalogo(formData)
      .then(() => {
        setSubmitting(false)
        setSnVisibleSubirCatalogo(false) //vuelvo a dashboard
      })
      .catch(() => 
              setSubmitting(false)
      )
    }
  }

  const validationSchema = Yup.object().shape({
    fileCatalogo: Yup
                  .mixed()
                  .required('El archivo es requerido')
                  .test("FILE_SIZE", "El archivo subido es demasiado grande , no puede superar los 40MB.", 
                                value => !value || (value && value.size <= 41943040))
                  .test("type", "Solo se aceptan los siguientes formatos: .pdf , .xls , .xlsx , .rar", (value) => {
                    return value && (
                        value.type === "application/vnd.ms-excel" || //xls
                        value.type === "application/zip" || //zip
                        value.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || //xlsx
                        value.type === 'application/pdf' //pdf
                    )})
  });



  return (
      <div>
         <Formik initialValues={{ fileCatalogo: null }} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ errors, touched, isSubmitting, setFieldValue }) => (
                <Form>
                    {
                        snAlta === -1 && <h3 className="card-header">Subir Catálogo</h3>
                    }
                    {
                        snAlta === 0 &&  <h3 className="card-header">Editar Catálogo</h3>
                                        
                    }
                    <div className="card-body">
                        <div className="form-group">
                              <input 
                                name="fileCatalogo"  
                                type="file"  
                                className={'form-control' + (errors.fileCatalogo && touched.fileCatalogo ? ' is-invalid' : '')} 
                                style={{border: 0}}
                                accept="application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip"
                                onChange={(event) => {
                                  setFieldValue("fileCatalogo", event.currentTarget.files[0]);
                                }}
                              />
                              <ErrorMessage name="fileCatalogo" component="div" className="invalid-feedback" />

                              <br/>

                              <div className="form-row">
                                <div className="form-group col">
                                    <ButtonCustom  
                                      type="submit" 
                                      isSubmitting={isSubmitting} 
                                      text="Subir Catálogo"
                                    />          
                                </div>
                                <div className="form-group col text-right">
                                    <ButtonCustom  
                                      type="button" 
                                      isSubmitting={isSubmitting} 
                                      text="Volver"
                                      onClick={handleClickVolver}
                                      className="btn btn-danger"
                                    />      
                                </div>
                            </div>    
                           
                        </div>
                    </div>
                </Form>
              )}
          </Formik>
      </div>
  );
}

const actionCreators = {
  agregarDownloadCatalogo: downloadCatalogoActions.agregarDownloadCatalogo,
  modificarDownloadCatalogo: downloadCatalogoActions.modificarDownloadCatalogo
};



export default connect(null, actionCreators)(AltaEditarPage);
