
import { BehaviorSubject } from 'rxjs';
import { fetchWrapper } from '@helpers';
const articuloSubject = new BehaviorSubject(null);
const baseUrl = `/articulo`;


export const articuloService = {
    getByFilters,
    modificarImagenArticulo,
    articulo: articuloSubject.asObservable(),
    get articuloValue () { return articuloSubject.value }
};



    


function getByFilters(payload) {
    const skip = parseInt(payload.skip);
    const take = parseInt(payload.take);
    const idArticulo = payload.idArticulo;
    const comboBoxMarca = payload.comboBoxMarca;
    const comboBoxFamilia = payload.comboBoxFamilia;
    const codigoArticulo = payload.codigoArticulo;
    const descripcionArticulo  = payload.descripcionArticulo;
    const utilidad = parseInt(payload.utilidad);
    const oferta = payload.oferta;
    const searchFilterGlobal = payload.searchFilterGlobal;
    return fetchWrapper.post(`${baseUrl}`, {
        skip,
        take,
        idArticulo,
        comboBoxMarca,
        comboBoxFamilia,
        codigoArticulo,
        descripcionArticulo,
        utilidad,
        oferta,
        searchFilterGlobal
    });
}

function modificarImagenArticulo(formData) {
    return fetchWrapper.postUpload(`${baseUrl}/modificar-articulo-imagen`, formData);
}
