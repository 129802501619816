import React from "react";
import { makeStyles } from "@mui/styles";


function ButtonCustom(props) {
    const { 
            text, 
            type, 
            isSubmitting,
            onClick, //puede que llegue o no este valor , depende quien lo llame
            className //puede que llegue o no este valor , depende quien lo llame
        } = props;
        
    const useStyles = makeStyles(() => ({
        style_button: {
            "&.btn-primary" : {
                backgroundColor: "#094293 !important", /*agregar !importantpara anular los estados */
                borderColor: "#094293 !important" /*agregar !importantpara anular los estados */
             },
            "&:hover, &:active,&:visited,&:focus" : {
                backgroundColor: "#0b52b8 !important", /*agregar !importantpara anular los estados */
                borderColor: "#0b52b8 !important" /*agregar !importantpara anular los estados */
            }
        }
      }));



    const classes = useStyles();
  

    return (
        <button 
            type={type} 
            disabled={isSubmitting} 
            className={ className ? className : `btn btn-primary ${classes.style_button}`} 
            onClick = {onClick}
        >
            {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
            {text}
        </button>                          
    )
}


export default ButtonCustom;

